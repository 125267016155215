import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import api from "./Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Container,
  Box,
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  Divider,
  TextField,
  Stack,
  Avatar,
  IconButton,
} from "@mui/material";
import { useRef } from "react";
import ModalPassword from "../components/modal/modalPassword";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faBell, faEdit, faSignOut } from "@fortawesome/free-solid-svg-icons";
import { FullButton } from "../components/button";

const BarangDetail = () => {
  const token = localStorage.getItem("Mitra-Token");
  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  const [data, setData] = useState({
    name: "",
    phone: "",
    phone: "",
    mitra_name: "",
    mitra_img_toko: "",
    mitra_img_toko_url: "",
    mitra_toko_coordinate: "",
    mitra_toko_address: "",
    mitra_wa: "",
    is_change: 0,
    modalPassword: false,
    update: 0,
    image:'',
  });
  const params = useParams();
  //---------------------param gadai---------------
  const [gadai, setGadai] = useState("");

  const fetchData = async () => {
    try {
      const response = await api.get("v1/account", {
        headers: {
          "Mitra-Token": token,
        },
      });
      return response.data.data;
    } catch (error) {
      throw error;
    }
  };
  const logout = () => {
    return api
      .put("v1/logout", null, {
        headers: { "Mitra-Token": token },
      })
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === "success") {
          toast.success(message);
          navigate("/");
          localStorage.clear();
          setUserData(null);
        }
      })
      .catch((error) => {
        if (error.response) {
          navigate("/");
          toast.error(error.response.data.message);
        }
      });
  };

  useEffect(() => {
    const fetchDataAndStopLoading = async () => {
      try {
        const data = await fetchData();
        setData({
          name: data.name,
          phone: data.phone,
          image: data.img_profile,
        });
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else if (error.message) {
          toast.error(error.message);
        } else {
          toast.error("An error occurred. Please try again later.");
        }
      }
    };
    fetchDataAndStopLoading();
  }, []);
  const goTo = (path) => () => {
    navigate(path);
  };
  const handleVerifiyPassword = (status) => {
    if (status) {
      handleSubmit();
    } else {
      toast.error("Gagal konfirmasi kata sandi");
    }
  };

  const handleSubmit = () => {
    const formData = new FormData();
    Object.entries(data).forEach(([key, val]) => {
      if (val !== "" && key !== "is_change") {
        formData.append(key, val);
      }
    });

    api
      .post("v1/dashboard/account", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Mitra-Token": token,
        },
      })
      .then((response) => {
        const { status, message } = response.data;
        if (status === "success") {
          toast.success(message);
          setData({ ...data, is_change: 0, modalPassword: false });
        }
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else if (error.message) {
          toast.error(error.message);
        } else {
          toast.error("An error occurred. Please try again later.");
        }
      });
  };
  const handleChange = (e) => {
    setData({
      ...data,
      is_change: 1,
      [e.target.name]: e.target.value,
    });
  };

  const handleImageChange = async (e) => {
    const files = e.target.files;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      setData({
        ...data,
        mitra_img_toko: files[0],
        is_change: 1,
        mitra_img_toko_url: reader.result,
      });
    };
  };

  return (
    <Container
      style={{
        justifyContent: "right",
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={12} md={6} lg={6} margin="10px auto">
          <Card>
            <CardContent>
            <Grid container >
              <Grid item lg={2} xs={3}>
              <Stack>
            <center>
              <Avatar src={data.image} alt="photoURL" sx={{marginTop: '10%',width:"50px",height:"50px"}} />
            </center>
            </Stack>
              </Grid>
              <Grid item lg={9} xs={8}>
                <Stack>
                <Typography variant="h6">
                  Hi, {data.name}
                </Typography>
                <Typography variant="body2">
                  {data.phone}
                </Typography>
                <Typography variant="caption">
                  {data.phone}
                </Typography>
                </Stack>
              
              </Grid>
              <Grid item lg={1} xs={1}>
              <Stack>
               
                <IconButton
                      onClick={goTo("/profil/edit")}
                      sx={{
                        color: "red",
                      }}
                    >
                      <FontAwesomeIcon icon={faEdit} fontSize={17} />
                </IconButton>
                <IconButton
                      onClick={logout}
                      sx={{
                        color: "red",
                      }}
                    >
                      <FontAwesomeIcon icon={faSignOut} fontSize={17} />
                </IconButton>
              </Stack>
              </Grid>
            </Grid>
            
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6} lg={6} margin="10px auto">
          <FullButton goto="/transactions" text="Transaksi"></FullButton>
          <FullButton goto="/gadai" text="Gadai"></FullButton>
          <FullButton goto="/dashboard" text="Dashboard Mitra"></FullButton>

        </Grid>
      </Grid>
    </Container>
  );
};

export default BarangDetail;
