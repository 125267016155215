import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import api from "../ApiDashboard";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Paper from "@mui/material/Paper";
import ModalDelete from "../../components/modal/modalHapus";
import ModalImage from "../../components/modal/modalImage";
import ModalTransfer from "../../components/modal/modalTransfer";
import {
  Container,
  Box,
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  Divider,
  IconButton,
  Stack,
  Popper,
  Grow,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Menu,
  ListItemIcon,
  Modal,
  Chip,
  Popover,
  TextField,
  InputBase,
  InputAdornment,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Pagination, Table, Spin } from "antd";
import {
  faAdd,
  faBan,
  faCalendarAlt,
  faCaretDown,
  faCheck,
  faDollarSign,
  faEllipsis,
  faEye,
  faEyeDropper,
  faEyedropper,
  faFile,
  faFilter,
  faImage,
  faMagnifyingGlass,
  faPlus,
  faPrint,
  faRotateLeft,
  faTimes,
  faTrash,
  faTrashCan,
  faTruck,
  faTruckArrowRight,
  faWrench,
} from "@fortawesome/free-solid-svg-icons";
import { FaParachuteBox } from "react-icons/fa";
import Slider from "react-slick";
import SearchBox from "../../components/search/searchBox";
import ModalTerjual from "../../components/modal/modalTerjual";
import ModalFilterDate from "../../components/modal/modalFilterDate";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import ReportsExcel from "../../components/reports/Reports";
import ModalKonfirmasi from "../../components/modal/modalKonfirmasi";
import ModalServices from "../../components/modal/modalServices";
import ModalReturn from "../../components/modal/modalReturn";

const Barang = () => {
  //---------------var filter--------------
  const [status, setStatus] = useState("n");
  const [kategori, setKategori] = useState("n");
  const [label, setLabel] = useState("n");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  //------------var search--------------
  const [searchMenuStat, setSearchMenuStat] = useState(null);
  const searchMenu = Boolean(searchMenuStat);
  const [search, setSearch] = useState("");
  const [searchText, setSearchText] = useState("ID");
  const [searchVal, setSearchVal] = useState("id");
  const [urlPrint, setUrlPrint] = useState("");

  const [modalService, setModalService] = useState(false);

  const token = localStorage.getItem("Mitra-Token");
  const navigate = useNavigate();

  const fetchData = async (page, rowsPerPage, clearSearch = "N") => {
    try {
      let url = `v1/dashboard/barang?page=${page}&per_page=${rowsPerPage}`;
      if (status !== "n") {
        url = url + "&status=" + status;
      }
      if (kategori !== "n") {
        url = url + "&kategori=" + kategori;
      }
      if (label !== "n") {
        url + "&label=" + label;
      }
      if (search !== "" && clearSearch === "N") {
        url = url + "&" + searchVal + "=" + search;
      }
      if (startDate) {
        const formattedDate = dayjs(startDate).format("YYYY-MM-DD");
        url = url + "&start=" + formattedDate;
      }
      if (endDate) {
        const formattedDate = dayjs(endDate).format("YYYY-MM-DD");
        url = url + "&end=" + formattedDate;
      }
      setUrlPrint(url + "&print=Y");

      const response = await api.get(url, {
        headers: { "Mitra-Token": token },
      });
      return response.data.data;
    } catch (error) {
      throw error;
    }
  };

  const addFilter = async () => {
    setFilterMenu(null);
    const responseData = await fetchData(page, rowsPerPage);
    setData(responseData.data);
    setRowsPerPage(responseData.last_page);
    setTotal(responseData.total);
    setPageSize(responseData.per_page);
  };
  const clearSearch = async () => {
    const responseData = await fetchData(page, rowsPerPage, "Y");
    setData(responseData.data);
    setRowsPerPage(responseData.last_page);
    setTotal(responseData.total);
    setPageSize(responseData.per_page);
  };
  const removeFilter = () => {
    setStatus("n");
    setKategori("n");
    setLabel("n");
  };
  const addFilterDate = async () => {
    setFilterDate(null);
    const responseData = await fetchData(page, rowsPerPage);
    setData(responseData.data);
    setRowsPerPage(responseData.last_page);
    setTotal(responseData.total);
    setPageSize(responseData.per_page);
  };

  const changeStart = (start) => {
    setStartDate(start);
  };
  const changeEnd = (end) => {
    setEndDate(end);
  };
  const goTo = (path) => () => {
    navigate(path);
  };
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [id, setId] = useState("");
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(0);
  const [pageSize, setPageSize] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalTerjualOpen, setModalTerjualOpen] = useState(false);
  const [modalImOpen, setModalImOpen] = useState(false);
  const [imagePreview, setImagePreview] = useState(false);
  const [modalTfOpen, setModalTfOpen] = useState(false);
  const [modal, setModal] = useState({
    terjual: false,
    image: false,
    imagePreview: false,
    transfer: false,
    return: false,
  });
  const [to, setTo] = useState("");
  const [imageList, setImageList] = useState([]);
  const [filterUrl, setFilterUrl] = useState("");
  const [final_price, setFinalPrice] = useState("");
  const [statusData, setStatusData] = useState(0);

  useEffect(() => {
    const fetchDataAndStopLoading = async () => {
      try {
        const responseData = await fetchData(page, rowsPerPage);
        setData(responseData.data);
        setRowsPerPage(responseData.last_page);
        setTotal(responseData.total);
        setPageSize(responseData.per_page);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else if (error.message) {
          toast.error(error.message);
        } else {
          toast.error("An error occurred. Please try again later.");
        }
      }
    };

    fetchDataAndStopLoading();
  }, [page, rowsPerPage]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  const handleSearch = async () => {
    const responseData = await fetchData(page, rowsPerPage);
    setData(responseData.data);
    setRowsPerPage(responseData.last_page);
    setTotal(responseData.total);
    setPageSize(responseData.per_page);
  };
  const searchBy = [
    { val: "id", text: "ID" },
    { val: "name", text: "Nama" },
    { val: "no_seri", text: "Nomor Seri" },
  ];
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterMenu, setFilterMenu] = useState(null);
  const [filterDate, setFilterDate] = useState(null);
  const open = Boolean(anchorEl);
  const filter = Boolean(filterMenu);
  const date = Boolean(filterDate);
  const handleClick = (id, image, sell_price, status) => (event) => {
    setStatusData(status);
    setId(id);
    setImageList(image.split(";"));
    setAnchorEl(event.currentTarget);
    setFinalPrice(sell_price);
  };
  const handleHapus = async () => {
    try {
      const response = await api.delete(`v1/dashboard/barang?id=${id}`, {
        headers: { "Mitra-Token": token },
      });

      const resData = response.data;
      if (resData.status == "success") {
        toast.success(resData.message);
        const responseData = await fetchData(page, rowsPerPage);
        setData(responseData.data);
        setRowsPerPage(responseData.last_page);
        setTotal(responseData.total);
        setPageSize(responseData.per_page);
      } else {
        toast.warning(resData.message);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("An error occurred. Please try again later.");
      }
    }
  };

  const handleTransfer = async (itemId, deskripsi) => {
    try {
      const response = await api.put(
        `v1/dashboard/transfer_barang?id=${id}&to=${itemId}&deskripsi=${deskripsi}`,
        null,
        {
          headers: { "Mitra-Token": token },
        }
      );
      if (response) {
        const resData = response.data;
        if (resData.status == "success") {
          toast.success(resData.message);
          const responseData = await fetchData(page, rowsPerPage);
          setData(responseData.data);
          setRowsPerPage(responseData.last_page);
          setTotal(responseData.total);
          setPageSize(responseData.per_page);
        } else {
          toast.warning(resData.message);
        }
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("An error occurred. Please try again later.");
      }
    }
  };
  const handleTerjual = async (final_price, other, cour) => {
    try {
      const response = await api.put(
        `v1/dashboard/barang/terjual?id=${id}&final_price=${final_price}&other=${other}&courier=${cour}`,
        null,
        {
          headers: { "Mitra-Token": token },
        }
      );
      if (response) {
        const resData = response.data;
        if (resData.status == "success") {
          toast.success(resData.message);
          const responseData = await fetchData(page, rowsPerPage);
          setData(responseData.data);
          setRowsPerPage(responseData.last_page);
          setTotal(responseData.total);
          setPageSize(responseData.per_page);
        } else {
          toast.warning(resData.message);
        }
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("An error occurred. Please try again later.");
      }
    }
  };
  const handleAktifkan = async () => {
    try {
      const response = await api.put(
        `v1/dashboard/barang/aktifkan?id=${id}`,
        null,
        {
          headers: { "Mitra-Token": token },
        }
      );
      if (response) {
        const resData = response.data;
        if (resData.status == "success") {
          toast.success(resData.message);
          const responseData = await fetchData(page, rowsPerPage);
          setData(responseData.data);
          setRowsPerPage(responseData.last_page);
          setTotal(responseData.total);
          setPageSize(responseData.per_page);
        } else {
          toast.warning(resData.message);
        }
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("An error occurred. Please try again later.");
      }
    }
  };
  const handleService = async (pic) => {
    try {
      const response = await api.put(
        `v1/dashboard/barang/servicekan?id=${id}&pic=${pic}`,
        null,
        {
          headers: { "Mitra-Token": token },
        }
      );
      if (response) {
        const resData = response.data;
        if (resData.status == "success") {
          toast.success(resData.message);
          const responseData = await fetchData(page, rowsPerPage);
          setData(responseData.data);
          setRowsPerPage(responseData.last_page);
          setTotal(responseData.total);
          setPageSize(responseData.per_page);
        } else {
          toast.warning(resData.message);
        }
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("An error occurred. Please try again later.");
      }
    }
  };
  const handleSembunyikan = async () => {
    try {
      const response = await api.put(
        `v1/dashboard/barang/sembunyikan?id=${id}`,
        null,
        {
          headers: { "Mitra-Token": token },
        }
      );
      if (response) {
        const resData = response.data;
        if (resData.status == "success") {
          toast.success(resData.message);
          const responseData = await fetchData(page, rowsPerPage);
          setData(responseData.data);
          setRowsPerPage(responseData.last_page);
          setTotal(responseData.total);
          setPageSize(responseData.per_page);
        } else {
          toast.warning(resData.message);
        }
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("An error occurred. Please try again later.");
      }
    }
  };
  const handleReturn = async (data) => {
    try {
      const response = await api.put(
        `v1/dashboard/barang/kembalikan?id=${id}&nominal=${data.nominal}&description=${data.description}`,
        null,
        {
          headers: { "Mitra-Token": token },
        }
      );
      if (response) {
        const resData = response.data;
        if (resData.status == "success") {
          toast.success(resData.message);
          const responseData = await fetchData(page, rowsPerPage);
          setData(responseData.data);
          setRowsPerPage(responseData.last_page);
          setTotal(responseData.total);
          setPageSize(responseData.per_page);
        } else {
          toast.warning(resData.message);
        }
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("An error occurred. Please try again later.");
      }
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseSearch = () => {
    setSearchMenuStat(null);
  };

  const columns = [
    {
      dataIndex: "name",
      ellipsis: true,
      key: "nama",
      render: (
        _,
        { name, no_seri, category, id, sell_price, status, image, buy_price }
      ) => (
        <>
          <Stack
            direction="row"
            spacing={1}
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick(id, image, sell_price, status)}
          >
            <Stack style={{ width: "90px" }}>
              <center>
                <Typography
                  variant="body"
                  style={{ margin: "auto 0", marginRight: "5px" }}
                >
                  #{id}
                </Typography>
              </center>
              <center>
                {status === 0 && (
                  <Chip
                    style={{ margin: "0 auto" }}
                    color="primary"
                    label="Aktif"
                    size="small"
                  />
                )}
                {status === 1 && (
                  <Chip
                    style={{ margin: "auto auto" }}
                    label="Tersembunyi"
                    size="small"
                  />
                )}
                {status === 2 && (
                  <Chip
                    style={{ margin: "auto auto" }}
                    color="info"
                    label="Gadai"
                    size="small"
                  />
                )}
                {status === 3 && (
                  <Chip
                    style={{ margin: "auto auto" }}
                    size="small"
                    color="error"
                    label="Ditransfer"
                  />
                )}
                {status === 4 && (
                  <Chip
                    style={{ margin: "auto auto" }}
                    size="small"
                    color="warning"
                    label="Service"
                  />
                )}
                {status === 5 && (
                  <Chip
                    style={{ margin: "auto auto" }}
                    size="small"
                    color="error"
                    label="Terjual"
                  />
                )}
              </center>
            </Stack>
            <Stack>
              <Typography variant="body2">
                {category} - {name}
              </Typography>
              <Typography variant="caption" sx={{ color: "rgba(0,0,0,0.4)" }}>
                {no_seri}
              </Typography>
              <Typography variant="caption" sx={{ color: "rgba(0,0,0,0.8)" }}>
                Rp.{" "}
                {sell_price
                  ? sell_price
                      .toLocaleString("id-ID", 0)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                  : sell_price}
              </Typography>
            </Stack>
          </Stack>
        </>
      ),
    },
  ];
  return (
    <Container
      style={{
        justifyContent: "right",
      }}
    >
      <ModalServices
        isOpen={modalService}
        onClose={() => setModalService(false)}
        onChange={handleService}
      ></ModalServices>
      <ModalDelete
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        onDelete={handleHapus}
      ></ModalDelete>
      <ModalTerjual
        isOpen={modalTerjualOpen}
        onClose={() => setModalTerjualOpen(false)}
        onChange={handleTerjual}
        price={final_price}
      ></ModalTerjual>
      <ModalImage
        isOpen={modalImOpen}
        image={imageList}
        onClose={() => setModalImOpen(false)}
      ></ModalImage>
      <ModalTransfer
        isOpen={modalTfOpen}
        onClose={() => setModalTfOpen(false)}
        onChange={handleTransfer}
      ></ModalTransfer>
      <ModalReturn
        isOpen={modal.return}
        onClose={() =>
          setModal({
            ...data,
            return: false,
          })
        }
        onChange={handleReturn}
      ></ModalReturn>
      {/* menu aksi */}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={goTo("/dashboard/barang/detail/" + id)}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faEye}></FontAwesomeIcon>
          </ListItemIcon>
          <Typography variant="inherit">Detail</Typography>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            handleClose();
            setModalImOpen(true);
          }}
        >
          <ListItemIcon>
            <FontAwesomeIcon icon={faImage}></FontAwesomeIcon>
          </ListItemIcon>
          <Typography variant="inherit">Gambar</Typography>
        </MenuItem>

        {(statusData === 0 || statusData === 1) && (
          <>
            <MenuItem
              onClick={(e) => {
                handleClose();
                setModalOpen(true);
              }}
              style={{ color: "red" }}
            >
              <ListItemIcon>
                <FontAwesomeIcon
                  icon={faTrash}
                  style={{ color: "red" }}
                ></FontAwesomeIcon>
              </ListItemIcon>
              <Typography variant="inherit">Hapus</Typography>
            </MenuItem>
            <MenuItem
              onClick={(e) => {
                handleClose();
                setModalTfOpen(true);
              }}
            >
              <ListItemIcon>
                <FontAwesomeIcon icon={faTruckArrowRight}></FontAwesomeIcon>
              </ListItemIcon>
              <Typography variant="inherit">Transfer</Typography>
            </MenuItem>
            <MenuItem
              onClick={(e) => {
                handleClose();
                setModalTerjualOpen(true);
              }}
            >
              <ListItemIcon>
                <FontAwesomeIcon icon={faDollarSign}></FontAwesomeIcon>
              </ListItemIcon>
              <Typography variant="inherit">Terjual</Typography>
            </MenuItem>
            <MenuItem
              onClick={(e) => {
                handleClose();
                setModalService(true);
              }}
            >
              <ListItemIcon>
                <FontAwesomeIcon icon={faWrench}></FontAwesomeIcon>
              </ListItemIcon>
              <Typography variant="inherit">Service</Typography>
            </MenuItem>
          </>
        )}
        {statusData === 1 && (
          <MenuItem
            onClick={(e) => {
              handleClose();
              handleAktifkan();
            }}
            style={{ color: "green" }}
          >
            <ListItemIcon>
              <FontAwesomeIcon
                icon={faCheck}
                style={{ color: "green" }}
              ></FontAwesomeIcon>
            </ListItemIcon>
            <Typography variant="inherit">Aktifkan</Typography>
          </MenuItem>
        )}
        {statusData === 5 && (
          <MenuItem
            onClick={(e) => {
              handleClose();
              setModal({
                ...data,
                return: true,
              });
            }}
          >
            <ListItemIcon>
              <FontAwesomeIcon icon={faRotateLeft}></FontAwesomeIcon>
            </ListItemIcon>
            <Typography variant="inherit">Dikembalikan</Typography>
          </MenuItem>
        )}
        {statusData === 0 && (
          <MenuItem
            onClick={(e) => {
              handleClose();
              handleSembunyikan();
            }}
          >
            <ListItemIcon>
              <FontAwesomeIcon icon={faBan}></FontAwesomeIcon>
            </ListItemIcon>
            <Typography variant="inherit">Sembunyikan</Typography>
          </MenuItem>
        )}
      </Menu>
      {/* modal filter */}
      <Popover
        id="filter-menu"
        open={filter}
        anchorEl={filterMenu}
        onClose={(e) => {
          setFilterMenu(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div style={{ padding: "20px", width: "250px" }}>
          <Typography variant="body" sx={{ fontSize: "14px" }}>
            Filter Data
          </Typography>
          <TextField
            select
            label="Status"
            defaultValue="n"
            fullWidth
            size="small"
            value={status}
            onChange={(e) => {
              setStatus(e.target.value);
            }}
            sx={{ mt: 2 }}
          >
            <MenuItem key="Tidak ada" value="n">
              Tidak ada
            </MenuItem>
            <MenuItem key="Aktif" value="0">
              Aktif
            </MenuItem>
            <MenuItem key="Tersembunyi" value="1">
              Tersembunyi
            </MenuItem>
            <MenuItem key="Gadai" value="2">
              Gadai
            </MenuItem>
            <MenuItem key="Transfer" value="3">
              Transfer
            </MenuItem>
            <MenuItem key="Service" value="4">
              Service
            </MenuItem>
            <MenuItem key="Terjual" value="5">
              Terjual
            </MenuItem>
          </TextField>
          <TextField
            select
            label="Kategori"
            defaultValue="n"
            fullWidth
            size="small"
            value={kategori}
            onChange={(e) => {
              setKategori(e.target.value);
            }}
            sx={{ mt: 2 }}
          >
            <MenuItem key="Tidak ada" value="n">
              Tidak ada
            </MenuItem>
            <MenuItem key="HP" value="HP">
              HP
            </MenuItem>
            <MenuItem key="TV" value="TV">
              TV
            </MenuItem>
            <MenuItem key="LAPTOP" value="LAPTOP">
              LAPTOP
            </MenuItem>
          </TextField>
          <TextField
            select
            label="Label"
            defaultValue="n"
            fullWidth
            size="small"
            value={label}
            onChange={(e) => {
              setLabel(e.target.value);
            }}
            sx={{ mt: 2 }}
          >
            <MenuItem key="Tidak ada" value="n">
              Tidak ada
            </MenuItem>
            <MenuItem key="Baru" value="Baru">
              Baru
            </MenuItem>
            <MenuItem key="Bekas" value="Bekas">
              Bekas
            </MenuItem>
          </TextField>
          <Button
            fullwidth
            variant="outlined"
            sx={{ mt: 2 }}
            onClick={() => {
              removeFilter();
            }}
          >
            Hilangkan
          </Button>
          <Button
            fullwidth
            variant="contained"
            sx={{ mt: 2, ml: 1 }}
            onClick={() => {
              addFilter();
            }}
          >
            Terapkan
          </Button>
        </div>
      </Popover>
      <ModalFilterDate
        open={date}
        anchorEl={filterDate}
        onClose={(e) => {
          setFilterDate(null);
        }}
        onApplyFilter={addFilterDate}
        start={startDate}
        end={endDate}
        changeStart={changeStart}
        changeEnd={changeEnd}
      ></ModalFilterDate>
      <Grid container spacing={1}>
        <Grid item xs={8} md={8} lg={8}>
          <Stack direction="row">
            <Typography gutterBottom variant="h6" component="div">
              Data Barang
            </Typography>
            <IconButton
              sx={{ top: "-5px", left: "2px", padding: "0 12px" }}
              variant="text"
              color="error"
              size="small"
              fullwidth
              onClick={goTo("/dashboard/barang/detail")}
            >
              <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
            </IconButton>
          </Stack>
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <Box display="flex" justifyContent="flex-end">
            <IconButton
              sx={{ right: 0 }}
              variant="text"
              color="error"
              size="small"
              fullwidth
              onClick={(e) => {
                setFilterMenu(e.currentTarget);
              }}
            >
              <FontAwesomeIcon icon={faFilter}></FontAwesomeIcon>
            </IconButton>
            <IconButton
              sx={{ right: 0 }}
              variant="text"
              color="error"
              size="small"
              fullwidth
              onClick={(e) => {
                setFilterDate(e.currentTarget);
              }}
            >
              <FontAwesomeIcon icon={faCalendarAlt}></FontAwesomeIcon>
            </IconButton>

            <ReportsExcel
              laporan={
                (startDate &&
                  endDate &&
                  "Data Barang " + startDate + " - " + endDate) ||
                "Data Barang (All)" + new Date().toLocaleDateString()
              }
              url={urlPrint}
            ></ReportsExcel>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12} lg={12}>
          <Card>
            <CardContent>
              <Grid container sx={{ mb: 1 }}>
                <Grid item xs={12} md={8} lg={8}>
                  <Typography gutterBottom variant="body" component="div">
                    Jumlah {total}
                  </Typography>
                </Grid>
                {/* search component */}
                <Grid item xs={12} md={4} lg={4}>
                  <Paper
                    style={{
                      borderRadius: "20px",
                      padding: "2px 2px 2px 2px",
                      shadow: "none",
                      border: "none",
                    }}
                  >
                    <Menu
                      dense
                      id="search-menu"
                      open={searchMenu}
                      anchorEl={searchMenuStat}
                      onClose={handleCloseSearch}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      {searchBy.map((item) => (
                        <MenuItem
                          onClick={(e) => {
                            handleCloseSearch();
                            setSearchVal(item.val);
                            setSearchText(item.text);
                          }}
                        >
                          <Typography variant="caption">{item.text}</Typography>
                        </MenuItem>
                      ))}
                    </Menu>
                    <Stack direction="row" spacing={0.5}>
                      <IconButton
                        size="small"
                        onClick={() => {
                          handleSearch();
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faMagnifyingGlass}
                        ></FontAwesomeIcon>
                      </IconButton>
                      <InputBase
                        fullWidth
                        size="small"
                        style={{ marginTop: "2px", fontSize: "14px" }}
                        placeholder={"Cari berdasarkan " + searchText}
                        value={search}
                        onChange={(e) => {
                          setSearch(e.target.value);
                        }}
                      ></InputBase>
                      {search !== "" && (
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            setSearch("");
                            clearSearch();
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faTimes}
                            style={{ fontSize: "15px" }}
                          ></FontAwesomeIcon>
                        </IconButton>
                      )}
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          setSearchMenuStat(e.currentTarget);
                        }}
                      >
                        <FontAwesomeIcon icon={faCaretDown}></FontAwesomeIcon>
                      </IconButton>
                    </Stack>
                  </Paper>
                </Grid>
              </Grid>

              <Table
                scroll={{
                  x: 400,
                }}
                columns={columns}
                dataSource={data}
                pagination={false}
                loading={isLoading}
              />
              <Pagination
                style={{ marginTop: "10px" }}
                current={page}
                pageSize={pageSize}
                total={total}
                size="small"
                onChange={handlePageChange}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Barang;
