import PropTypes from "prop-types";
// @mui
import { styled } from "@mui/material/styles";
import { Box, Stack, AppBar, Toolbar, IconButton, Grid } from "@mui/material";
// utils
// components
//
import Searchbar from "./Searchbar";
import { Link, useNavigate } from "react-router-dom";
import { bgBlur } from "../../utils/cssStyles";
import AccountPopover from "./AccountPopover";
import {
  faList,
  faUserAlt,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const HEADER_MOBILE = 50;

const HEADER_DESKTOP = 60;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
  [theme.breakpoints.up("lg")]: {
    width: "100%",
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));
Navi.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Navi({ onOpenNav }) {
  const navigate = useNavigate();
  const goTo = (path) => () => {
    navigate(path);
  };
  return (
    <StyledRoot>
      <StyledToolbar>
        <Grid container>
          <Grid item lg={1} xs={2} justifyContent="start">
            <IconButton onClick={goTo("/beranda")}>
              <FontAwesomeIcon icon={faArrowLeft} fontSize={20} color="red" />
            </IconButton>
          </Grid>
          <Grid item lg={10} xs={8}>
            <center>
              <img
                src={process.env.REACT_APP_CONTENT_URL + "/profile/logo-1.jpg"}
                style={{
                  width: "auto",
                  objectFit: "contain",
                  height: 30,
                }}
              />
            </center>
          </Grid>
          <Grid item lg={1} xs={2}></Grid>
        </Grid>
      </StyledToolbar>
    </StyledRoot>
  );
}
