import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../css/Home.css";
import "../css/Base.css";
import api from "./Api.js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProductCard from "../components/scroll-barang";
import {
  Container,
  Box,
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
} from "@mui/material";
import logo_3 from "../img/logo-3.png";
import logo_4 from "../img/logo-4.png";
import logo_5 from "../img/logo-5.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { AppTasks } from "../sections/@dashboard/app";
import "slick-carousel/slick/slick-theme.css";
import InfiniteScroll from "../components/load-barang";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faLaptop,
  faMobile,
  faPhoneSquare,
  faTv,
} from "@fortawesome/free-solid-svg-icons";

const Home = () => {
  const token = localStorage.getItem("Mitra-Token");
  const [products, setProducts] = useState([]);
  const [data_loaded, setDataLoaded] = useState(false);

  const navigate = useNavigate();
  const goTo = (path) => () => {
    navigate(path);
  };
  const fetchData = async () => {
    try {
      const response = await api.get("barang_top_ten");
      return response.data.data;
    } catch (error) {
      throw error;
    }
  };
  useEffect(() => {
    const fetchDataAndStopLoading = async () => {
      try {
        const data = await fetchData();
        setProducts(data);
      } catch (error) {}
    };
    fetchDataAndStopLoading();
  }, []);
  const logout = () => {
    return api
      .put("v1/logout", null, {
        headers: { "Mitra-Token": token },
      })
      .then((response) => {
        const { status, message, data } = response.data;

        if (status === "success") {
          toast.success(message);
          navigate("/");
          localStorage.clear();
          setUserData(null);
        }
      })
      .catch((error) => {
        if (error.response) {
          navigate("/");
          toast.error(error.response.data.message);
        }
      });
  };
  // const tokenString = localStorage.getItem("Mitra-Token");
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    pauseOnHover: true,
    accessbility: false,
    responsive: [
      {
        breakpoint: 480,
        settings: {},
      },
    ],
  };
  return (
    <Container
      style={{
        justifyContent: "right",
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} xl={12} lg={12}>
          <div className="carousel-container">
            <Slider {...settings}>
              <div>
                <img src={logo_3} className="carousel-image" />
              </div>
              <div>
                <img src={logo_3} className="carousel-image" />
              </div>
              <div>
                <img src={logo_3} className="carousel-image" />
              </div>
            </Slider>
          </div>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Typography gutterBottom variant="caption" component="div">
            Paling Sering Dilihat
          </Typography>
          <ProductCard product={products}></ProductCard>
        </Grid>

        <Grid item xs={12} sm={12} lg={12}>
          <Typography gutterBottom variant="caption" component="div">
            Kategori
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={6} sm={2} lg={2}>
              <Card
                sx={{ padding: 1 }}
                onClick={() => {
                  navigate("/barang?kategori=tv");
                }}
              >
                <CardContent>
                  <center>
                    <Typography gutterBottom variant="h6" sx={{ margin: 0 }}>
                      <FontAwesomeIcon icon={faTv}></FontAwesomeIcon>
                    </Typography>
                    <Typography gutterBottom variant="caption" component="div">
                      Televisi
                    </Typography>
                  </center>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6} sm={2} lg={2}>
              <Card
                sx={{ padding: 1 }}
                onClick={() => {
                  navigate("/barang?kategori=hp");
                }}
              >
                <CardContent>
                  <center>
                    <Typography gutterBottom variant="h6" sx={{ margin: 0 }}>
                      <FontAwesomeIcon icon={faMobile}></FontAwesomeIcon>
                    </Typography>
                    <Typography gutterBottom variant="caption" component="div">
                      Handphone
                    </Typography>
                  </center>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6} sm={2} lg={2}>
              <Card
                sx={{ padding: 1 }}
                onClick={() => {
                  navigate("/barang?kategori=laptop");
                }}
              >
                <CardContent>
                  <center>
                    <Typography gutterBottom variant="h6" sx={{ margin: 0 }}>
                      <FontAwesomeIcon icon={faLaptop}></FontAwesomeIcon>
                    </Typography>
                    <Typography gutterBottom variant="caption" component="div">
                      Laptop
                    </Typography>
                  </center>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6} sm={2} lg={2}>
              <Card
                sx={{ padding: 1 }}
                onClick={() => {
                  navigate("/barang");
                }}
              >
                <CardContent>
                  <center>
                    <Typography gutterBottom variant="h6" sx={{ margin: 0 }}>
                      <FontAwesomeIcon icon={faBars}></FontAwesomeIcon>
                    </Typography>
                    <Typography gutterBottom variant="caption" component="div">
                      Lainnya
                    </Typography>
                  </center>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Home;
