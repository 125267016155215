import PropTypes from "prop-types";
// @mui
import { styled } from "@mui/material/styles";
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  Grid,
  Typography,
  Divider,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { bgBlur } from "../../utils/cssStyles";
import {
  faList,
  faUserAlt,
  faArrowLeft,
  faAngleLeft,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import routes from "../../../src/routes";
import { useEffect } from "react";
// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const HEADER_MOBILE = 50;

const HEADER_DESKTOP = 60;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  backgroundColor: "white",
  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",

  [theme.breakpoints.up("lg")]: {
    width: "100%",
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: HEADER_DESKTOP,
  },
}));
Navi.propTypes = {
  onOpenNav: PropTypes.func,
};
export default function Navi({ onOpenNav }) {
  const pathToText = {
    "/dashboard": "Dashboard",
    "/menu": "Menu",
    "/detail": "Detail Data",
    "/dashboard/settings/profile": "Akun & Profil Mitra",
    "/dashboard/barang/detail": "Detail Barang",
  };
  const location = useLocation();
  const navPath = pathToText[location.pathname] || "Detail";

  const navigate = useNavigate();
  const goTo = () => () => {
    navigate(-1);
  };
  return (
    <StyledRoot>
      <StyledToolbar sx={{ padding: "3px" }}>
        <Grid container>
          <Grid item lg={12} xs={12} sm={12} justifyContent="start">
            <Box display="flex">
              <IconButton onClick={goTo()}>
                <FontAwesomeIcon icon={faAngleLeft} fontSize={20} color="red" />
              </IconButton>
              <Typography
                variant="subtitle2"
                style={{
                  color: "rgba(0,0,0,0.8)",
                  marginTop: "8px",
                  marginLeft: "5px",
                  fontWeight: "bold",
                }}
                gutterBottom
              >
                {navPath}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </StyledToolbar>
    </StyledRoot>
  );
}
