import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// @mui
import { styled, alpha } from "@mui/material/styles";
import {
  Box,
  Link,
  Button,
  Drawer,
  Typography,
  Avatar,
  Stack,
  Divider,
  Chip,
  IconButton,
  Badge,
} from "@mui/material";
// mock
import useResponsive from "../../hooks/useResponsive";
import Scrollbar from "../../../src/components/scrollbar/";
import NavSection from "../../../src/components/nav-section/";
//
import navConfig from "./config";
import api from "../../pages/ApiDashboard";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faSignOut,
  faUserAlt,
} from "@fortawesome/free-solid-svg-icons";
import Pusher, { Members } from "pusher-js";

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const StyledAccount = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

const StyledMitra = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(1, 1),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  border: "dotted 1px grey",
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};
export default function Nav({ openNav, onCloseNav }) {
  const [isLogin, setIsLogin] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [status, setStatus] = useState(false);
  const [status_akun, setStatusAkun] = useState("");
  const [statusOwner, setOwner] = useState(false);
  const [image, setImage] = useState("");
  const [idMitra, setIdMitra] = useState("");
  const [mitra, setMitra] = useState(false);
  const [sd, setSd] = useState("");
  const [count_notif, setCountNotif] = useState(0);
  const [notification, setNotification] = useState("");

  const token = localStorage.getItem("Mitra-Token");
  const navigate = useNavigate();

  useEffect(() => {
    const response = api
      .get("v1/account", {
        headers: { "Mitra-Token": token },
      })
      .then((response) => {
        const { status, message, data } = response.data;

        if (status === "success") {
          setName(data.name);
          setPhone(data.phone);
          setImage(data.img_profile);
          setIdMitra(data.id);

          localStorage.setItem("MemberId", data.id);

          setStatusAkun(data.status);
          localStorage.setItem("status_akun", data.status);
          if (data.status === "OWNER") {
            setOwner(true);
          }
          if (data.is_mitra === 1) {
            setMitra(true);
          }
          if (data.is_special === 1) {
            setStatus(true);
          }
          setIsLogin(true);
          setSd(data.end_mitra_date);
        }
      })
      .catch((error) => {
        if (error.response) {
          setName("");
          setPhone("");
          setImage("");
          setIdMitra("");
          localStorage.setItem("MemberId", "");

          setMitra(false);
          setIsLogin(false);
          toast.error(error.response.data.message);
        }
      });
  }, [token]);

  useEffect(() => {
    const response = api
      .get("v1/dashboard/notif/count", {
        headers: { "Mitra-Token": token },
      })
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === "success") {
          setCountNotif(data.count);
        }
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.message);
        }
      });
  }, []);

  useEffect(() => {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: process.env.REACT_APP_CLUSTER,
      encrypted: true,
      debug: true,
    });

    const channel = pusher.subscribe("mitra62-dev");
    channel.bind("Notif-Event", (data) => {
      if (
        data.message.type == "notif" &&
        localStorage.getItem("MemberId") == data.message.member_id
      ) {
        if (data.message.count !== 0) {
          setCountNotif(data.message.count);
        } else {
          setCountNotif(0);
        }
      }
    });

    return () => {
      channel.unbind("mitra62-dev");
      pusher.unsubscribe("mitra62-dev");
    };
  }, []);

  const { pathname } = useLocation();

  const isDesktop = useResponsive("up", "lg");

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      right: 25,
      top: 3,
      backgroundColor: "red",
      color: "white",
      fontSize: "9px",
    },
  }));
  useEffect(() => {
    const badgeElement = document.querySelector(".MuiBadge-badge");
    if (badgeElement) {
      badgeElement.style.display = count_notif === 0 ? "none" : "";
    }
  }, [count_notif]);

  const logout = () => {
    return api
      .put("v1/logout", null, {
        headers: { "Mitra-Token": token },
      })
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === "success") {
          toast.success(message);
          navigate("/");
          localStorage.clear();
          setUserData(null);
        }
      })
      .catch((error) => {
        if (error.response) {
          navigate("/");
          toast.error(error.response.data.message);
        }
      });
  };
  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box sx={{ px: 1, py: 1, display: "inline-flex" }}></Box>

      <Box sx={{ mb: 1, mx: 1 }}>
        <StyledAccount>
          <Stack>
            <center>
              <Avatar src={image} alt="photoURL" />
              {mitra ? (
                <Typography variant="caption">#{idMitra}</Typography>
              ) : (
                ""
              )}
            </center>
          </Stack>
          <Box sx={{ ml: 2 }}>
            <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
              {name}
            </Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {phone}
            </Typography>
            <Chip
              size="small"
              color={statusOwner ? "error" : "primary"}
              variant="contained"
              label={status_akun}
            />
          </Box>
          <Box sx={{ ml: 5 }} display="flex" justifyContent="flex-end">
            <Stack>
              <StyledBadge badgeContent={count_notif}>
                <IconButton
                  onClick={() => navigate("/dashboard/notification")}
                  sx={{
                    color: "text.primary",
                  }}
                >
                  <FontAwesomeIcon icon={faBell} fontSize={17} />
                </IconButton>
              </StyledBadge>
              <IconButton
                onClick={logout}
                sx={{
                  color: "text.primary",
                }}
              >
                <FontAwesomeIcon icon={faSignOut} fontSize={17} />
              </IconButton>
            </Stack>
          </Box>
        </StyledAccount>
      </Box>
      <Box sx={{ mb: 2, mx: 1 }}>
        <StyledMitra>
          <Box sx={{ ml: 2 }}>
            <Stack>
              <Typography variant="caption" sx={{ color: "text.primary" }}>
                Status Mitra
              </Typography>
              {mitra ? (
                <Chip
                  size="small"
                  color="primary"
                  variant="contained"
                  label="AKTIF"
                />
              ) : (
                <Chip
                  size="small"
                  color="error"
                  variant="contained"
                  label="TIDAK AKTIF"
                />
              )}
            </Stack>
          </Box>
          <Box sx={{ ml: 6 }} display="flex" justifyContent="flex-end">
            <Stack>
              <center>
                <Typography variant="caption" sx={{ color: "text.primary" }}>
                  Sampai Dengan
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "grey", fontWeight: "bolder" }}
                >
                  {sd}
                </Typography>
              </center>
            </Stack>
          </Box>
        </StyledMitra>
      </Box>
      <NavSection data={navConfig} />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: "background.default",
              borderRightStyle: "dashed",
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
