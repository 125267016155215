import { Navigate, useRoutes } from "react-router-dom";
// layouts
import DashboardLayout from "./layouts";
import HomeLayout from "./layouts/HomeLayout";
import BaseLayout from "./layouts/BaseLayout";
import DetailLayout from "./layouts/DetailLayout";
//--------------non user------------
import Home from "./pages/Home";
import DetailBarangMp from "./pages/DetailBarang";
import BarangNonUser from "./pages/Barang";
import Login from "./pages/Login";
import UpgradeMitra from "./pages/UpgradeMitra";
import Register from "./pages/Register";
import Otp from "./pages/Otp";
import MembersProfile from "./pages/MembersProfile";
import MembersEditProfile from "./pages/MembersEditProfile";

//----- dashboard----------
import DashboardIndex from "./pages/dashboard/Index";
import Barang from "./pages/dashboard/Barang";
import BarangDetail from "./pages/dashboard/BarangDetail";
import BarangKeluar from "./pages/dashboard/BarangKeluar";
import BarangMasuk from "./pages/dashboard/BarangMasuk";
import BarangTransfer from "./pages/dashboard/BarangTransfer";
import Gadai from "./pages/dashboard/Gadai";
import GadaiDetail from "./pages/dashboard/GadaiDetail";
import Subsidi from "./pages/dashboard/Subsidi";
import SubsidiDetail from "./pages/dashboard/SubsidiDetail";
import Services from "./pages/dashboard/Services";
import ServicesDetail from "./pages/dashboard/ServicesDetail";
import Notification from "./pages/dashboard/Notification";
import Settings from "./pages/dashboard/Settings";
import Members from "./pages/dashboard/SettingMembers";
import Profile from "./pages/dashboard/SettingProfile";
import OtpVerification from "./pages/OtpVerification";

//
export default function Router() {
  const routes = useRoutes([
    {
      path: "/",
      element: <HomeLayout />,
      children: [
        { element: <Navigate to="/beranda" />, index: true },
        { path: "/beranda", element: <Home /> },
        { path: "/profil", element: <MembersProfile /> },
        { path: "/profil/edit", element: <MembersEditProfile /> },
        { path: "/barang", element: <BarangNonUser /> },
        { path: "/detail/barang", element: <DetailBarangMp /> },
      ],
    },
    {
      path: "/auth",
      element: <BaseLayout />,
      children: [
        { path: "/auth/login", element: <Login /> },
        { path: "/auth/register", element: <Register /> },
        { path: "/auth/otp", element: <Otp /> },
        { path: "/auth/upgrade-mitra", element: <UpgradeMitra /> },
      ],
    },
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      children: [
        { path: "/dashboard", element: <DashboardIndex /> },
        {
          path: "/dashboard/barang",
          element: <Barang />,
        },
        { path: "/dashboard/transaksi", element: <BarangMasuk /> },
        { path: "/dashboard/keluar", element: <BarangKeluar /> },
        { path: "/dashboard/transfer", element: <BarangTransfer /> },
        { path: "/dashboard/gadai", element: <Gadai /> },
        { path: "/dashboard/subsidi", element: <Subsidi /> },
        { path: "/dashboard/services", element: <Services /> },
        { path: "/dashboard/notification", element: <Notification /> },
        { path: "/dashboard/settings", element: <Settings /> },
        { path: "/dashboard/settings/members", element: <Members /> },
        // { path: "/dashboard/settings/profile", element: <Profile /> },
      ],
    },
    {
      path: "/dashboard/barang/detail",
      element: <DetailLayout />,
      children: [
        {
          path: "/dashboard/barang/detail",
          element: <BarangDetail />,
        },
        {
          path: "/dashboard/barang/detail/:id",
          element: <BarangDetail />,
        },
      ],
    },
    {
      path: "/dashboard/subsidi/detail",
      element: <DetailLayout />,
      children: [
        {
          path: "/dashboard/subsidi/detail",
          element: <SubsidiDetail />,
        },
        {
          path: "/dashboard/subsidi/detail/:id",
          element: <SubsidiDetail />,
        },
      ],
    },
    {
      path: "/dashboard/gadai/detail/:id",
      element: <DetailLayout />,
      children: [
        {
          path: "/dashboard/gadai/detail/:id",
          element: <GadaiDetail />,
        },
      ],
    },
    {
      path: "/dashboard/services/detail/:id",
      element: <DetailLayout />,
      children: [
        {
          path: "/dashboard/services/detail/:id",
          element: <ServicesDetail />,
        },
      ],
    },
    {
      path: "/dashboard/settings/profile",
      element: <DetailLayout />,
      children: [
        {
          path: "/dashboard/settings/profile",
          element: <Profile />,
        },
      ],
    },
    {
      path: "/dashboard/otp",
      element: <DetailLayout />,
      children: [
        {
          path: "/dashboard/otp",
          element: <OtpVerification />,
        },
      ],
    },
  ]);

  return routes;
}
