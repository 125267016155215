import React, { useEffect, useState, version } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../css/Login.css";
import "../css/Form.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import api from "./Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UAParser from "ua-parser-js";
import {
  Container,
  Box,
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  TextField,
  InputAdornment,
  Divider,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

const UpgradeMitra = () => {
  const token = localStorage.getItem("Mitra-Token");
  const [nama, setNama] = useState("");
  const [phone, setPhone] = useState("");
  const [alamat, setAlamat] = useState("");
  const [verified, setVerified] = useState(null);
  const [reject_message, setRejectMessage] = useState("");

  const navigate = useNavigate();
  const goTo = (path) => () => {
    navigate(path);
  };
  const [term, setTerm] = useState(false);

  const cekTerm = () => {
    console.log(term);
    setTerm(!term);
  };
  
  useEffect(() => {
      const response = api
        .get("v1/upgrade_status", {
          headers: { "Mitra-Token": token },
        })
        .then((response) => {
          const { status, message, data } = response.data;
          if (status === "success") {
            setVerified(data.verified_status);
            setRejectMessage(data.reject_message)
            console.log(verified)
          }
        })
        .catch((error) => {
          if (error.response) {
            toast.error(error.response.data.message);
          }
        });
  }, [verified]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (term !== false) {
      api
        .post(
          "v1/upgrade_mitra",
          {
            mitra_name: nama,
            mitra_wa: phone,
            mitra_toko_address: alamat,
          },
          { headers: { "Mitra-Token": token } }
        )
        .then((response) => {
          const { status, message, data } = response.data;
          if (status === "success") {
            toast.success(message);
            setVerified(0);
          }
        })
        .catch((error) => {
          console.log(error);
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            toast.error(error.response.data.message);
          } else if (error.message) {
            toast.error(error.message);
          } else {
            toast.error("An error occurred. Please try again later.");
          }
        });
    } else {
      toast.error("Klik setuju dengan syarat & ketentuan");
    }
  };
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Container>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={3}
      >
        <Grid item xs={12} sm={6} l={6}>
          <Card sx={{ p: 4, borderRadius: "50px" }}>
            <label className="header-text">Pengajuan Upgrade Mitra</label>
            {(verified === null || verified === 2) && (<>
              {verified === 2 && (
              <>
              <hr></hr>
               <Typography variant="body">
                Permintaaan Upgrade Mitra ditolak :
               </Typography>
               <br></br>
               <Typography variant="subtitle">{reject_message}</Typography>

              <hr></hr>
              
              </>
            )}
            <label className="under-header-text">
              Sialahkan isi data-data berikut
            </label>
            <form onSubmit={handleSubmit}>
              <TextField
                label="Nama Toko"
                variant="outlined"
                size="small"
                value={nama}
                onChange={(e) => {
                  setNama(e.target.value);
                }}
                fullWidth
                required
                sx={{ mb: 3 }}
              />
              <TextField
                label="No Hp"
                variant="outlined"
                size="small"
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
                fullWidth
                required
                sx={{ mb: 3 }}
              />
              <TextField
                variant="outlined"
                size="small"
                value={alamat}
                onChange={(event) => {
                  setAlamat(event.target.value);
                }}
                rows={4}
                fullWidth
                multiline
                label="Alamat"
              ></TextField>
              <center>
                <FormControlLabel
                  sx={{ fontSize: "1px", mb: 1 }}
                  control={<Checkbox onChange={cekTerm} size="small" />}
                  label={
                    <Typography variant="body2">Syarat & ketentuan</Typography>
                  }
                />
              </center>
              <Button
                variant="contained"
                onClick={handleSubmit}
                sx={{ width: "100%" }}
              >
                Ajukan
              </Button>
            </form>
            </>)}
            {verified === 0 && (
              <>
              <hr></hr>
               <Typography variant="body">
                Permintaaan Upgrade mitra dalam peninjauan, silah cek status upgrade berkala.
               </Typography>

              </>
            )}

            
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default UpgradeMitra;
