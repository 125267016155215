import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import api from "../ApiDashboard";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Container,
  Box,
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  Divider,
  TextField,
} from "@mui/material";
import { useRef } from "react";
import ModalPassword from "../../components/modal/modalPassword";

const BarangDetail = () => {
  const token = localStorage.getItem("Mitra-Token");
  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  const [data, setData] = useState({
    name: "",
    phone: "",
    mitra_name: "",
    mitra_img_toko: "",
    mitra_img_toko_url: "",
    mitra_toko_coordinate: "",
    mitra_toko_address: "",
    mitra_wa: "",
    is_change: 0,
    modalPassword: false,
    update: 0,
  });
  const params = useParams();
  //---------------------param gadai---------------
  const [gadai, setGadai] = useState("");

  const fetchData = async () => {
    try {
      const response = await api.get("v1/dashboard/account", {
        headers: {
          "Mitra-Token": token,
        },
      });
      return response.data.data;
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    const fetchDataAndStopLoading = async () => {
      try {
        const data = await fetchData();
        setData({
          name: data.name,
          phone: data.phone,
          mitra_name: data.mitra_name,
          mitra_img_toko_url: data.mitra_img_toko,
          mitra_toko_coordinate: data.mitra_toko_cordinate,
          mitra_toko_address: data.mitra_toko_address,
          mitra_wa: data.mitra_wa,
        });
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else if (error.message) {
          toast.error(error.message);
        } else {
          toast.error("An error occurred. Please try again later.");
        }
      }
    };
    fetchDataAndStopLoading();
  }, []);
  const goTo = (path) => () => {
    navigate(path);
  };
  const handleVerifiyPassword = (status) => {
    if (status) {
      handleSubmit();
    } else {
      toast.error("Gagal konfirmasi kata sandi");
    }
  };

  const handleSubmit = () => {
    const formData = new FormData();
    Object.entries(data).forEach(([key, val]) => {
      if (val !== "" && key !== "is_change") {
        formData.append(key, val);
      }
    });

    api
      .post("v1/dashboard/account", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Mitra-Token": token,
        },
      })
      .then((response) => {
        const { status, message } = response.data;
        if (status === "success") {
          toast.success(message);
          setData({ ...data, is_change: 0, modalPassword: false });
        }
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else if (error.message) {
          toast.error(error.message);
        } else {
          toast.error("An error occurred. Please try again later.");
        }
      });
  };
  const handleChange = (e) => {
    setData({
      ...data,
      is_change: 1,
      [e.target.name]: e.target.value,
    });
  };

  const handleImageChange = async (e) => {
    const files = e.target.files;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      setData({
        ...data,
        mitra_img_toko: files[0],
        is_change: 1,
        mitra_img_toko_url: reader.result,
      });
    };
  };

  return (
    <Container
      style={{
        justifyContent: "right",
      }}
    >
      <ModalPassword
        isOpen={data.modalPassword}
        onClose={() => setData({ ...data, modalPassword: false })}
        onChange={handleVerifiyPassword}
      ></ModalPassword>
      <Grid container spacing={1} sx={{}}>
        <Grid item xs={12} md={6} lg={6} margin="0 auto">
          {data.mitra_img_toko_url ? (
            <>
              <Card
                onClick={() => {
                  document.getElementById("select-image").click();
                }}
                style={{
                  flex: "0 0 auto",
                  borderRadius: "5px",
                  width: 100,
                  margin: "0 auto",
                  boxShadow: "none",
                  background: "transparent",
                }}
              >
                <CardMedia>
                  <CardMedia
                    component="img"
                    height="100"
                    image={data.mitra_img_toko_url}
                  />
                </CardMedia>
              </Card>
              <center>
                <Typography variant="caption" style={{ fontSize: "10px" }}>
                  Ketuk gambar untuk mengubah
                </Typography>
              </center>
            </>
          ) : (
            <Card
              onClick={() => {
                document.getElementById("select-image").click();
              }}
              style={{
                flex: "0 0 auto",
                padding: "20px 10px 20px 10px",
                width: 100,
                boxShadow: "none",
                border: "dashed 1px red",
                borderRadius: "5px",
              }}
            >
              <CardContent style={{ padding: "5px 10px 10px 10px " }}>
                <center>
                  <Typography variant="caption" color="red" style={{}}>
                    Pilih Gambar
                  </Typography>
                </center>
              </CardContent>
            </Card>
          )}
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={12} md={6} lg={6} margin="10px auto">
          <Card>
            <CardContent>
              <input
                type="file"
                accept="image/*"
                id="select-image"
                style={{ display: "none" }}
                ref={fileInputRef}
                onChange={(e) => {
                  handleImageChange(e);
                }}
              ></input>

              <TextField
                variant="outlined"
                size="small"
                value={data.name}
                name="name"
                onChange={(event) => {
                  handleChange(event);
                }}
                fullWidth
                required
                sx={{ mb: 3 }}
                label="Nama"
              ></TextField>
              <TextField
                variant="outlined"
                size="small"
                value={data.phone}
                name="phone"
                onChange={(event) => {
                  handleChange(event);
                }}
                fullWidth
                required
                sx={{ mb: 1 }}
                label="Nomor WA"
              ></TextField>
              <Divider variant="fullWidth" sx={{ mt: 1, mb: 2 }}></Divider>

              <TextField
                variant="outlined"
                size="small"
                value={data.mitra_name}
                name="mitra_name"
                onChange={(event) => {
                  handleChange(event);
                }}
                fullWidth
                required
                sx={{ mb: 3 }}
                label="Nama Mitra/Toko"
              ></TextField>
              <TextField
                variant="outlined"
                size="small"
                value={data.mitra_wa}
                name="mitra_wa"
                onChange={(event) => {
                  handleChange(event);
                }}
                fullWidth
                required
                sx={{ mb: 3 }}
                label="Nomor WA Mitra/Toko"
              ></TextField>
              <TextField
                variant="outlined"
                size="small"
                value={data.mitra_toko_address}
                onChange={(event) => {
                  handleChange(event);
                }}
                rows={4}
                sx={{ mb: 1 }}
                fullWidth
                multiline
                name="mitra_toko_address"
                required
                label="Alamat Toko"
              ></TextField>

              {data.is_change == 1 && (
                <>
                  <Divider variant="fullWidth" sx={{ mb: 2 }}></Divider>
                  <Button
                    onClick={() => {
                      setData({ ...data, modalPassword: true });
                    }}
                    size="small"
                    variant="contained"
                    fullWidth
                  >
                    Simpan
                  </Button>
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default BarangDetail;
