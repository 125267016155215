import React, { useState } from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { Grid, IconButton, Modal } from "@mui/material";
import { toast } from "react-toastify";

import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import api from "../../pages/ApiDashboard";
function ReportsExcel({ laporan, url }) {
  const token = localStorage.getItem("Mitra-Token");
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      const response = await api.get(url, {
        headers: {
          "Mitra-Token": token,
        },
      });
      const resData = response.data;
      if (resData.status == "success") {
        setData(resData.data);
        generateExcelFile();
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("An error occurred. Please try again later.");
      }
    }
  };
  const generateExcelFile = async () => {
    const wb = new ExcelJS.Workbook();
    wb.created = new Date();
    wb.modified = new Date();
    wb.lastPrinted = new Date();
    const ws = wb.addWorksheet();

    // Set lebar Column menggunakan key bisa dengan huruf bisa dengan index angka
    ws.columns = [{ key: "A", width: 10 }];

    ws.getRow(1).getCell("A").value = laporan + " - ";

    ws.getRow(1).getCell("A").font = {
      bold: true,
      size: 16,
    };

    ws.mergeCells("A1", "J1");

    const rowHeader = ws.getRow(3);

    // Buat styling cell header menggunakan perulangan agar tidak per cell kita bikinnya
    let first = Object.keys(data[0]);
    for (let i = 1; i <= first.length; i++) {
      rowHeader.getCell(i).border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      rowHeader.getCell(i).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "191970" },
      };
      rowHeader.getCell(i).alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      rowHeader.getCell(i).font = {
        bold: true,
        size: 11,
        color: { argb: "FFFFFF" },
      };
    }
    first.map((item, index) => {
      rowHeader.getCell(index + 1).value = item;
    });

    data.map((item, index) => {
      const row = ws.getRow(4 + index);
      first.map((items, indexs) => {
        row.getCell(indexs + 1).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        row.getCell(indexs + 1).value = item[items];
      });
    });

    const buf = await wb.xlsx.writeBuffer();
    saveAs(new Blob([buf]), laporan + ".xlsx");
  };

  return (
    <>
      <IconButton
        sx={{ right: 0 }}
        variant="text"
        color="error"
        size="small"
        fullwidth
        onClick={(e) => {
          fetchData();
        }}
      >
        <FontAwesomeIcon icon={faFile}></FontAwesomeIcon>
      </IconButton>
    </>
  );
}

export default ReportsExcel;
