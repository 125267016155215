import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // Ganti dengan base URL API Anda
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    const {
      config,
      response: { status },
    } = error;
    if (status === 403) {
      window.location.href = "/auth/upgrade-mitra";
      // localStorage.clear();
      toast.warning("Anda belum mendaftar sebagia mitra!");
      return Promise.reject(error);
    } else if (status == 401) {
      window.location.href = "/auth/login";
      localStorage.clear();
      toast.warning("Silahkan masuk terlebih dahulu1!");
      return Promise.reject(error);
    } else if (status === 200) {
      return new Promise((resolve) => {});
    } else if (status == 405) {
      toast.error(error.message);
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  }
);

export default api;
