import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Paper from "@mui/material/Paper";
import {
  Container,
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  Button,
  IconButton,
  Stack,
  MenuItem,
  Menu,
  ListItemIcon,
  Chip,
  Popover,
  TextField,
  InputBase,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Pagination, Table, Spin } from "antd";
import {
  faAngleRight,
  faCalendarAlt,
  faCaretDown,
  faCheck,
  faEye,
  faFilter,
  faMagnifyingGlass,
  faPlus,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import ModalKonfirmasi from "../../components/modal/modalKonfirmasi";

const Settings = () => {
  //---------------var filter--------------
  const [filterMenu, setFilterMenu] = useState(null);
  const [filterDate, setFilterDate] = useState(null);
  //------------var search--------------
  const [searchMenuStat, setSearchMenuStat] = useState(null);
  const searchMenu = Boolean(searchMenuStat);
  const [search, setSearch] = useState("");
  const [searchText, setSearchText] = useState("Nomor HP");
  const navigate = useNavigate();

  const goTo = (path) => () => {
    navigate(path);
  };

  return (
    <Container
      style={{
        justifyContent: "right",
      }}
    >
      <Typography gutterBottom variant="h6" component="div">
        Pengaturan Lainnya
      </Typography>
      <Card
        sx={{ padding: 2, marginBottom: 2 }}
        onClick={() => {
          navigate("/dashboard/settings/members");
        }}
      >
        <Grid container>
          <Grid item xs={8} md={8} lg={8}>
            <Box display="flex">
              <Typography variant="body">Daftar members</Typography>
            </Box>
          </Grid>
          <Grid item xs={4} md={4} lg={4}>
            <Box display="flex" justifyContent="flex-end">
              <Typography variant="body">
                <FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Card>
      <Card
        sx={{ padding: 2 }}
        onClick={() => {
          navigate("/dashboard/settings/profile");
        }}
      >
        <Grid container>
          <Grid item xs={8} md={8} lg={8}>
            <Box display="flex">
              <Typography variant="body">Akun & Profil</Typography>
            </Box>
          </Grid>
          <Grid item xs={4} md={4} lg={4}>
            <Box display="flex" justifyContent="flex-end">
              <Typography variant="body">
                <FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};

export default Settings;
