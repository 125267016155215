import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import api from "./Api";

import "../css/Otp.css";
import "../css/Form.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import logo_3 from "../img/logo-4.png";
import { toast } from "react-toastify";
import {
  Container,
  Box,
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  TextField,
  InputAdornment,
  Divider,
} from "@mui/material";
import "react-toastify/dist/ReactToastify.css";

const Otp = () => {
  const [otp, setOtp] = useState(new Array(6).fill(""));

  const navigate = useNavigate();
  const location = useLocation();
  const [otpToken, setOtpToken] = useState(null);

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;
    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };
  useEffect(() => {
    setOtpToken(location.state?.otpToken);
    if (!location.state?.otpToken) {
      toast.error("Silahkan Login Terlebih Dahulu");
      navigate("/auth/login");
    }
  }, [location]);
  useEffect(() => {
    if (otpToken) {
      api
        .get("otp", {
          headers: {
            "Otp-Token": otpToken,
          },
        })
        .then((response) => {
          if (response.data.status === "success") {
            toast.success(response.data.message);
          }
        })
        .catch((error) => {
          if (error.response) {
            toast.error(error.response.data.message);
            navigate("/auth/login");
          } else {
            toast.error(error.message);
            navigate("/auth/login");
          }
        });
    }
  }, [otpToken]);

  const handleSubmit = (event) => {
    event.preventDefault();
    api
      .post(
        "verified_otp",
        {
          otp: otp.join(""),
        },
        {
          headers: {
            "Otp-Token": otpToken,
          },
        }
      )
      .then((response) => {
        const { status, message, data } = response.data;

        if (status === "success") {
          localStorage.setItem("Refresh-Token", data["Refresh-Token"]);
          localStorage.setItem("Mitra-Token", data.Token);
          toast.success(message);
          navigate("/");
        }
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      });
  };

  return (
    <Container>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={3}
      >
        <Grid item xs={12} sm={6} l={6}>
          <Card sx={{ p: 4, borderRadius: "50px" }}>
            <center>
              <h4 className="header-text">OTP</h4>
              {""}
              <label className="under-header-text">
                Silahkan masukan 6 digit OTP yang diterima
              </label>
            </center>

            <form onSubmit={handleSubmit}>
              <center>
                {otp.map((data, index) => {
                  return (
                    <input
                      className="otp-input"
                      type="text"
                      name="otp"
                      maxLength="1"
                      style={{ marginTop: "10%", marginBottom: "15%" }}
                      key={index}
                      required
                      value={data}
                      onChange={(e) => handleChange(e.target, index)}
                      onFocus={(e) => e.target.select()}
                    />
                  );
                })}
              </center>
              <Button type="submit" variant="contained" fullWidth>
                Verifikasi OTP
              </Button>
            </form>
            <Typography variant="body2">
              Otp tidak masuk? <Button variant="text">Kirim Ulang Otp</Button>
            </Typography>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Otp;
