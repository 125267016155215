import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Paper,
  Grid,
  CardMedia,
  Chip,
  Stack,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { NumericFormat } from "react-number-format";

const ProductCard = ({ id, name, price, mitra, views, url }) => {
  const [formatted, setFormattedNum] = useState("");

  const map = [
    { suffix: "T", threshold: 1e12 },
    { suffix: "B", threshold: 1e9 },
    { suffix: "M", threshold: 1e6 },
    { suffix: "K", threshold: 1e3 },
    { suffix: "", threshold: 1 },
  ];
  const navigate = useNavigate();
  const goTo = (path) => () => {
    navigate(path);
  };
  return (
    <Card
      onClick={goTo("/detail/barang?b=" + id)}
      style={{
        flex: "0 0 auto",
        marginRight: 1,
      }}
    >
      <CardMedia component="img" height="150" image={url} />
      <CardContent height="100" style={{ padding: "5px 10px 10px 10px " }}>
        <Typography
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: "2",
            WebkitBoxOrient: "vertical",
            lineHeight: "15px",
            fontSize: "13px",
          }}
        >
          {name}
        </Typography>

        <Typography
          color="red"
          noWrap
          sx={{
            fontWeight: "bolder",
            fontSize: "13px",
            padding: "2px 0",
          }}
        >
          Rp
          <NumericFormat
            value={price}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
          />
        </Typography>
        <Typography
          noWrap
          style={{
            fontSize: "10px",
            marginBottom: "3px",
            color: "rgba(0,0,0,0.9)",
            overflow: "hidden",
          }}
        >
          {mitra}
        </Typography>
        <h1 style={{ fontSize: "10px", color: "rgba(0,0,0,0.5)" }}>
          <FontAwesomeIcon
            icon={faEye}
            style={{ fontSize: "8px" }}
          ></FontAwesomeIcon>
          {(() => {
            const found = map.find((x) => Math.abs(views) >= x.threshold);
            if (found) {
              const formatted =
                (views / found.threshold).toFixed(1) + found.suffix;
              return formatted;
            }
          })()}
        </h1>
      </CardContent>
    </Card>
  );
};

export default ProductCard;
