import PropTypes from "prop-types";
// @mui
import { bgBlur } from "../../utils/cssStyles";
import { styled } from "@mui/material/styles";
import { Box, Stack, AppBar, Toolbar, IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserAlt,
  faList,
  faBars,
  faSignOut,
  faSignOutAlt,
  faHome,
  faHomeAlt,
  faDoorOpen,
  faStore,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import api from "../../pages/ApiDashboard";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const HEADER_MOBILE = 50;

const HEADER_DESKTOP = 60;
const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
  [theme.breakpoints.up("lg")]: {
    width: "100%",
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: HEADER_DESKTOP,
    paddingLeft: NAV_WIDTH,
  },
}));

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};
export default function Header({ onOpenNav }) {
  const token = localStorage.getItem("Mitra-Token");
  const navigate = useNavigate();
  const goHome = () => {
    navigate("/");
  };
  return (
    <StyledRoot>
      <StyledToolbar>
        <IconButton
          onClick={onOpenNav}
          sx={{
            color: "text.primary",
            display: { lg: "none" },
          }}
        >
          <FontAwesomeIcon icon={faBars} fontSize={17} />
        </IconButton>

        <Box sx={{ flexGrow: 1 }}>
          <center>
            <img
              src={process.env.REACT_APP_CONTENT_URL + "/profile/logo-1.jpg"}
              style={{
                width: "auto",
                objectFit: "contain",
                height: 30,
              }}
            />
          </center>
        </Box>

        <Stack direction="row" alignItems="right" spacing={{}}>
          <IconButton
            onClick={goHome}
            sx={{
              color: "text.primary",
            }}
          >
            <FontAwesomeIcon icon={faStore} fontSize={17} />
          </IconButton>
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  );
}
