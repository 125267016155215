import React, { useEffect, useState } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import axios from "axios";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Container,
  Box,
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  Divider,
  TextField,
  Chip,
  Avatar,
  Stack,
  IconButton,
} from "@mui/material";
import { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaWhatsapp } from "react-icons/fa";
import {
  faPhone,
  faPhoneAlt,
  faShare,
  faShareAlt,
} from "@fortawesome/free-solid-svg-icons";

import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { Helmet } from "react-helmet";
import { RWebShare } from "react-web-share";
import { Share, WhatsApp } from "@mui/icons-material";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import DocumentMeta from "react-document-meta";

const DetailBarang = () => {
  const token = localStorage.getItem("Mitra-Token");
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [images, setImages] = useState([]);

  const [data, setData] = useState({
    img: "",
    no_seri: "",
    category: "",
    label: "",
    description: "",
    sell_price: "",
    mitra_name: "",
    mitra_img_toko_url: "",
    mitra_toko_coordinate: "",
    mitra_toko_address: "",
    mitra_wa: "",
  });
  //---------------------param gadai---------------
  const [params, setParams] = useSearchParams();

  const fetchData = async () => {
    try {
      const response = await api.get(`detail_barang?b=${params.get("b")}`);
      return response.data.data;
    } catch (error) {
      throw error;
    }
  };
  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });
  const shareImage = async () => {
    try {
      const imageUrl =
        "https://content.mitra62.com/img/barang/barang-189-HngaSN4cqZ.jpg";

      const response = await fetch(imageUrl);
      const blob = await response.blob();

      const file = new File([blob], "image.jpg", { type: "image/jpeg" });

      await navigator.share({
        files: [file],
        title: "Contoh Bagikan Gambar ke WhatsApp",
        text: "Gambar ini dibagikan melalui aplikasi web.",
      });
      console.log("Gambar berhasil dibagikan.");
    } catch (error) {
      console.error("Gagal membagikan gambar:", error);
    }
  };
  useEffect(() => {
    const fetchDataAndStopLoading = async () => {
      try {
        const data = await fetchData();
        setImages(
          data.image.split(";").filter(function (item) {
            if (item !== null && item !== "") {
              return item;
            }
          })
        );
        setData({
          name: data.name,
          no_seri: data.no_seri,
          category: data.category,
          label: data.label,
          sell_price: data.sell_price
            .toLocaleString("id-ID", 0)
            .replace(/\B(?=(\d{3})+(?!\d))/g, "."),
          description: data.description,
          img: data.image,

          mitra_name: data.mitra_name,
          mitra_img_toko_url: data.mitra_img_toko,
          mitra_toko_coordinate: data.mitra_toko_cordinate,
          mitra_toko_address: data.mitra_toko_address,
          mitra_wa: data.mitra_wa,
        });
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else if (error.message) {
          toast.error(error.message);
        } else {
          toast.error("An error occurred. Please try again later.");
        }
      }
    };
    fetchDataAndStopLoading();
  }, []);
  const goTo = (path) => () => {
    navigate(path);
  };

  return (
    <Container
      style={{
        justifyContent: "right",
      }}
    >
      <Helmet>
        <title>{data.name}</title>
        <meta name="description" content={data.description} />
        <meta property="og:image" content={images[0]} />
        <meta
          property="og:url"
          content={
            process.env.REACT_APP_BASE_URL +
            `/detail/barang?b=${params.get("b")}`
          }
        />
      </Helmet>
      <Grid container spacing={1} sx={{}}>
        <Grid item xs={12} md={6} lg={6} margin="0 auto"></Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          md={6}
          lg={6}
          sx={{
            margin: "0 auto",
            padding: "0",
          }}
        >
          <Carousel
            showArrows={false}
            showStatus={false}
            showThumbs={true}
            emulateTouch={true}
            infiniteLoop={true}
            selectedItem={0}
            dynamicHeight={true}
            autoPlay={false}
            thumbWidth={"50px"}
            thumbHeight={"50px"}
          >
            {images.map((item, index) => {
              return (
                <div key={index} style={{ height: "300px" }}>
                  <img
                    src={item}
                    style={{
                      objectFit: "contain",
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </div>
              );
            })}
          </Carousel>
        </Grid>
        <Grid item xs={12} md={6} lg={6} margin="10px auto">
          <Card>
            <CardContent sx={{ padding: 2 }}>
              <Typography variant="body" sx={{ fontWeight: "bold" }}>
                {" "}
                {data.name}
              </Typography>
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", marginTop: "5px" }}
              >
                Rp {data.sell_price}
              </Typography>
              <hr></hr>

              <Typography
                variant="body2"
                sx={{ fontWeight: "bold", marginTop: "5px", color: "grey" }}
              >
                Deskripsi
              </Typography>
              <Typography variant="catopion">{data.description}</Typography>
            </CardContent>
          </Card>
          <Card sx={{ mt: "10px", padding: 0 }}>
            <CardContent>
              <Grid container>
                <Grid item xs={4} md={2} lg={2}>
                  <center>
                    <Avatar
                      src={data.mitra_img_toko_url}
                      sx={{
                        mr: 1,
                      }}
                    />
                  </center>
                </Grid>
                <Grid item xs={6} md={8} lg={8}>
                  <Stack>
                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                      {data.mitra_name}
                    </Typography>
                    <Typography variant="caption" noWrap>
                      {data.mitra_toko_address}
                    </Typography>
                    <Stack direction="row" sx={{ padding: 0, margin: 0 }}>
                      <IconButton
                        size="small"
                        sx={{
                          color: "red",
                        }}
                        onClick={shareImage}
                      >
                        <WhatsApp />
                      </IconButton>

                      <RWebShare data={{}}>
                        <IconButton
                          size="small"
                          sx={{
                            color: "red",
                          }}
                        >
                          <Share />
                        </IconButton>
                      </RWebShare>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default DetailBarang;
