import { useState, useEffect } from "react";
// @mui
import { alpha } from "@mui/material/styles";
import {
  Box,
  Divider,
  Typography,
  Stack,
  MenuItem,
  Avatar,
  IconButton,
  Popover,
  Button,
  Chip,
  Card,
  Badge,
} from "@mui/material";
// mocks_

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserAlt, faList } from "@fortawesome/free-solid-svg-icons";
import api from "../../pages/Api";
import { Navigate, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";

// ----------------------------------------------------------------------
const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -20,
    top: 0,
    backgroundColor: "red",
    padding: "0 5px",
    color: "white",
  },
}));
const BadgeSpecial = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -25,
    top: 0,
    backgroundColor: "orange",
    padding: "0 5px",
    color: "white",
  },
}));

const MENU_OPTIONS = [
  {
    label: "Detail Akun",
    path: "profil/",
  },
  {
    label: "Transaksi",
    path: "transaksi/",
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const [isLogin, setIsLogin] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [status, setStatus] = useState(false);
  const [image, setImage] = useState("");
  const [mitra, setMitra] = useState(false);

  const token = localStorage.getItem("Mitra-Token");
  const navigate = useNavigate();

  useEffect(() => {
    if (token !== null) {
      const response = api
        .get("v1/account", {
          headers: { "Mitra-Token": token },
        })
        .then((response) => {
          const { status, message, data } = response.data;

          if (status === "success") {
            setName(data.name);
            setPhone(data.phone);
            setImage(data.img_profile);
            if (data.is_mitra === 1) {
              setMitra(true);
            }
            if (data.is_special === 1) {
              setStatus(true);
            }
            setIsLogin(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            setName("");
            setPhone("");
            setImage("");
            setMitra(false);
            setIsLogin(false);
            toast.error(error.response.data.message);
          }
        });
    } else {
      setName("");
      setPhone("");
      setImage("");
      setMitra(false);
      setIsLogin(false);
    }
  }, [token]);

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };
  const goTo = (path) => () => {
    navigate(path);
    setOpen(null);
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
            },
          }),
        }}
      >
        <FontAwesomeIcon icon={faUserAlt} fontSize={20} color="red" />
      </IconButton>
      {isLogin ? (
        <Popover
          open={Boolean(open)}
          anchorEl={open}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          PaperProps={{
            sx: {
              p: 0,
              mt: 1.5,
              ml: 0.75,
              width: 180,
              "& .MuiMenuItem-root": {
                typography: "body2",
                borderRadius: 0.75,
              },
            },
          }}
        >
          <Box sx={{ my: 1.5, px: 2.5, display: "flex" }}>
            <Avatar src={image} sx={{ mr: 1, borderRadius: "50px" }} />
            <Stack spacing={0.1}>
              <Typography variant="body1" noWrap>
                {name}
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: "text.secondary" }}
                noWrap
              >
                {phone}
              </Typography>
            </Stack>
          </Box>
          <Chip
            size="small"
            color={status ? "warning" : "error"}
            variant="outlined"
            label={status ? "Spesial" : "Member"}
            sx={{ ml: 2.5, width: "40%" }}
          />
          {mitra && (
            <Chip
              size="small"
              color="error"
              label="Mitra"
              sx={{ ml: 0.5, width: "30%" }}
            />
          )}
          <Stack sx={{ p: 1 }}>
            {MENU_OPTIONS.map((option) => (
              <MenuItem key={option.label} onClick={goTo(option.path)}>
                {option.label}
              </MenuItem>
            ))}
            <MenuItem key="Gadai" onClick={goTo("dashboard")}>
              {status ? (
                "Gadai"
              ) : (
                <BadgeSpecial badgeContent={"Spesial"}>Gadai</BadgeSpecial>
              )}
            </MenuItem>
            <MenuItem key="Dashboard Mitra" onClick={goTo("dashboard")}>
              {mitra ? (
                "Dashboard Mitra"
              ) : (
                <StyledBadge badgeContent={"Mitra"}>
                  Dashboard Mitra
                </StyledBadge>
              )}
            </MenuItem>
          </Stack>
        </Popover>
      ) : (
        <Popover
          open={Boolean(open)}
          anchorEl={open}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          PaperProps={{
            sx: {
              p: 0,
              mt: 1.5,
              ml: 0.75,
              width: 180,
              "& .MuiMenuItem-root": {
                typography: "body2",
                borderRadius: 0.75,
              },
            },
          }}
        >
          <Box sx={{ my: 1.5, px: 2.5 }}>
            <Typography variant="subtitle2" Wrap>
              <center>Silahkan masuk terlebih dahulu</center>
            </Typography>
          </Box>
          <Divider variant="middle" sx={{ borderStyle: "dashed" }} />
          <Stack sx={{ p: 1 }}>
            <Button variant="contained" onClick={goTo("auth/login")}>
              Masuk
            </Button>
            <Button
              variant="outlined"
              sx={{ mt: 1 }}
              onClick={goTo("auth/register")}
            >
              Daftar
            </Button>
          </Stack>
        </Popover>
      )}
    </>
  );
}
