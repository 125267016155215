import React, { useState } from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamation, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { Grid, MenuItem, Modal, TextField } from "@mui/material";

function ModalInvitation({ isOpen, onClose, onChange }) {
  if (!isOpen) return null;
  const [data, setData] = useState({
    member_id: "",
    as: "ADMIN",
  });

  const handleKonfirmasi = () => {
    onChange(data);
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 300,
          p: 2,
          textAlign: "center",
        }}
      >
        <Typography id="modal-modal-title" variant="h6" component="h3">
          KONFIRMASI
        </Typography>
        <FontAwesomeIcon
          icon={faExclamation}
          style={{ fontSize: "50px", marginTop: "20px" }}
        />
        <Typography id="modal-modal-description" sx={{ mt: 4 }}>
          Undang pengguna sebagai member.
        </Typography>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          value={data.member_id}
          onChange={(e) => {
            setData({
              ...data,
              member_id: e.target.value,
            });
          }}
          sx={{ mb: 2 }}
          label="Member ID"
        ></TextField>
        <TextField
          select
          variant="outlined"
          size="small"
          fullWidth
          value={data.as}
          onChange={(e) => {
            setData({
              ...data,
              [as]: e.target.value,
            });
          }}
          sx={{ mb: 2 }}
          label="Sebagai"
        >
          <MenuItem key="ADMIN" value="ADMIN">
            ADMIN
          </MenuItem>
          <MenuItem key="STAFF" value="STAFF">
            STAFF
          </MenuItem>
        </TextField>
        <Grid container spacing={1} sx={{ mt: 1 }}>
          <Grid item xs={6}>
            <Button variant="outlined" onClick={onClose} fullWidth>
              TIDAK
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" onClick={handleKonfirmasi} fullWidth>
              IYA
            </Button>
          </Grid>
        </Grid>
      </Card>
    </Modal>
  );
}

export default ModalInvitation;
