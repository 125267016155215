import { useState } from "react";
// @mui
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import {
  Input,
  Slide,
  Button,
  IconButton,
  InputAdornment,
  ClickAwayListener,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { bgBlur } from "../../utils/cssStyles";

import { faSearch } from "@fortawesome/free-solid-svg-icons";
// utils
// component

// ----------------------------------------------------------------------

const HEADER_MOBILE = 50;
const HEADER_DESKTOP = 60;

const StyledSearchbar = styled("div")(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  top: 0,
  left: 0,
  zIndex: 99,
  width: "100%",
  display: "flex",
  position: "absolute",
  alignItems: "center",
  height: HEADER_MOBILE,
  padding: theme.spacing(0, 3),
  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
  [theme.breakpoints.up("md")]: {
    height: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

export default function Searchbar() {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      navigate("/barang?search=" + event.target.value);
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        {!open && (
          <IconButton onClick={handleOpen}>
            <FontAwesomeIcon icon={faSearch} fontSize={20} color="red" />
          </IconButton>
        )}

        <Slide direction="down" in={open} mountOnEnter unmountOnExit>
          <StyledSearchbar>
            <Input
              autoFocus
              fullWidth
              disableUnderline
              placeholder="Cari di Mitra62"
              startAdornment={
                <InputAdornment position="start">
                  <FontAwesomeIcon icon={faSearch} color="red" />
                </InputAdornment>
              }
              sx={{ mr: 1, fontWeight: "fontWeightBold" }}
              type="search"
              onKeyDown={(e) => {
                handleKeyDown(e);
              }}
            />
          </StyledSearchbar>
        </Slide>
      </div>
    </ClickAwayListener>
  );
}
