import React, { useState } from "react";
import "../css/Register.css";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../css/Form.css";
import api from "./Api";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  faEye,
  faEyeSlash,
  faCheck,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import {
  Container,
  Box,
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  TextField,
  InputAdornment,
  FormControlLabel,
  Divider,
  Checkbox,
} from "@mui/material";

import logo_3 from "../img/logo-4.png";
import $ from "jquery";

const Register = () => {
  const [username, setUsername] = useState("");
  const [term, setTerm] = useState(false);
  const [hp, setHp] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(true);

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };
  const handleHpChange = (event) => {
    setHp(event.target.value);
  };
  const handlePasswordChange = (event) => {
    if ($("#confirmPassword").val() !== $("#password").val()) {
      setPasswordMatch(false);
    } else {
      setPasswordMatch(true);
    }
    if ($("#password").val() === "") {
    }
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    if ($("#confirmPassword").val() !== $("#password").val()) {
      setPasswordMatch(false);
    } else {
      setPasswordMatch(true);
    }
    setConfirmPassword(event.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const cekTerm = () => {
    console.log(term);
    setTerm(!term);
  };
  const navigate = useNavigate();

  const goTo = (path) => () => {
    navigate(path);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (term !== false) {
      api
        .post("register", {
          name: username,
          phone: hp,
          password: password,
        })
        .then((response) => {
          const { status, message, data } = response.data;
          if (status === "success") {
            toast.success(message);
            navigate("/auth/login");
          }
        })
        .catch((error) => {
          console.log(error);
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            toast.error(error.response.data.message);
          } else if (error.message) {
            toast.error(error.message);
          } else {
            toast.error("An error occurred. Please try again later.");
          }
        });
    } else {
      toast.error("Klik setuju dengan syarat & ketentuan");
    }
  };

  return (
    <Container>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={3}
      >
        <Grid item xs={12} sm={6} lg={6}>
          <Card sx={{ p: 4, borderRadius: "50px" }}>
            <label className="header-text">Daftar</label>
            <label
              className="under-header-text"
              style={{ marginBottom: "24px" }}
            >
              Silahkan daftarkan akun terlebih dahulu
            </label>
            <form onSubmit={handleSubmit}>
              <TextField
                key="Nama"
                value={username}
                onChange={handleUsernameChange}
                fullWidth
                label="Nama"
                sx={{ mb: 3 }}
                size="small"
                required
              />
              <TextField
                key="Hp"
                value={hp}
                onChange={handleHpChange}
                fullWidth
                label="No Hp"
                size="small"
                sx={{ mb: 3 }}
                required
              />
              <TextField
                label="Password"
                variant="outlined"
                size="small"
                type={showPassword ? "text" : "password"}
                onChange={handlePasswordChange}
                value={password}
                sx={{ mb: 3 }}
                fullWidth
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <FontAwesomeIcon
                        onClick={togglePasswordVisibility}
                        icon={showPassword ? faEyeSlash : faEye}
                      />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                label="Konfirmasi Password"
                variant="outlined"
                size="small"
                type={showConfirmPassword ? "text" : "password"}
                onChange={handleConfirmPasswordChange}
                value={confirmPassword}
                sx={{ mb: 1 }}
                fullWidth
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <FontAwesomeIcon
                        onClick={toggleConfirmPasswordVisibility}
                        icon={showConfirmPassword ? faEyeSlash : faEye}
                      />
                    </InputAdornment>
                  ),
                }}
              />
              <center>
                <FormControlLabel
                  sx={{ fontSize: "1px", mb: 1 }}
                  control={<Checkbox onChange={cekTerm} size="small" />}
                  label={
                    <Typography variant="body2">Syarat & ketentuan</Typography>
                  }
                />
              </center>
              <Button type="submit" variant="contained" fullWidth size="small">
                DAFTAR
              </Button>
              <Typography variant="body2">
                Sudah punya akun?
                <Button variant="text" onClick={goTo("/auth/login")}>
                  Masuk di sini
                </Button>
              </Typography>
            </form>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Register;
