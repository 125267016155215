import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import api from "../ApiDashboard";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo_3 from "../../img/logo-3.png";
import Paper from "@mui/material/Paper";
import {
  Container,
  Box,
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  Divider,
  IconButton,
  Stack,
  TextField,
  InputAdornment,
  MenuItem,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
} from "@mui/material";
import Slider from "react-slick";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TableComponent from "../../components/table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa";
import { MutatingDots } from "react-loader-spinner";
import { Pagination, Table, Spin } from "antd";
import { styled } from "@mui/material/styles";
import {
  faAdd,
  faCamera,
  faImage,
  faPlus,
  faTimes,
  faTimesRectangle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import RadioGroupContext from "@mui/material/RadioGroup/RadioGroupContext";
import { useRef } from "react";
import { DateField, LocalizationProvider } from "@mui/x-date-pickers";
import { NumericFormat } from "react-number-format";
import ModalItemService from "../../components/modal/modalItemService";

const ServicesDetail = () => {
  const token = localStorage.getItem("Mitra-Token");
  const navigate = useNavigate();
  const [status, setStatus] = useState(0);
  const [id_barang, setIdBarang] = useState("");
  const [nama_barang, setNamaBarang] = useState("");
  const [data, setData] = useState([]);
  const params = useParams();
  const location = useLocation();
  const [modal, setModal] = useState({ addItems: false });
  const [total_item, setTotalItem] = useState(0);

  const fetchData = async () => {
    try {
      const response = await api.get(
        "v1/dashboard/services/detail?id=" + params.id,
        {
          headers: {
            "Mitra-Token": token,
          },
        }
      );
      return response.data.data;
    } catch (error) {
      throw error;
    }
  };
  const fetchDataItems = async () => {
    try {
      const response = await api.get(
        "v1/dashboard/services/items?id_services=" + params.id,
        {
          headers: {
            "Mitra-Token": token,
          },
        }
      );
      return response.data.data;
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    let totalItems = 0;
    data.map(
      (item) =>
        (totalItems += parseInt(item.services_price) - parseInt(item.price))
    );
    setTotalItem(parseInt(totalItems));
  }, [data]);
  useEffect(() => {
    const fetchDataAndStopLoading = async () => {
      try {
        const data = await fetchData();
        setStatus(data.status);
        setIdBarang(data.id_barang);
        setNamaBarang(data.category + " - " + data.name);
        const dataItems = await fetchDataItems();
        setData(dataItems);
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else if (error.message) {
          toast.error(error.message);
        } else {
          toast.error("An error occurred. Please try again later.");
        }
      }
    };
    fetchDataAndStopLoading();
  }, []);
  const handleAddItems = async (data) => {
    try {
      const formData = new FormData();
      formData.append("item", data.item);
      formData.append("price", data.price);
      formData.append("services_price", data.service_price);
      formData.append("description", data.description);
      formData.append("id_services", params.id);

      const response = await api.post("v1/dashboard/services/items", formData, {
        headers: {
          "Mitra-Token": token,
        },
      });
      const resData = response.data;
      if (resData.status == "success") {
        const dataItems = await fetchDataItems();
        setData(dataItems);
      }
    } catch (error) {
      toast.error(error);
    }
  };
  const handleDeleteItems = async (id) => {
    try {
      const response = await api.delete(
        "v1/dashboard/services/items?id=" + id,
        {
          headers: {
            "Mitra-Token": token,
          },
        }
      );
      const resData = response.data;
      if (resData.status == "success") {
        toast.success(resData.message);
        const dataItems = await fetchDataItems();
        setData(dataItems);
      }
    } catch (error) {
      toast.error(error);
    }
  };
  const columns = [
    {
      width: "100%",
      render: (_, { item, price, id, services_price, description }) => (
        <>
          <Stack spacing={1}>
            <Typography variant="body1">{item}</Typography>
            <Typography variant="caption">
              Modal : Rp.{" "}
              {price
                ? price
                    .toLocaleString("id-ID", 0)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                : price}
            </Typography>
            <Typography variant="caption">
              Service : Rp.{" "}
              {services_price
                ? services_price
                    .toLocaleString("id-ID", 0)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                : services_price}
            </Typography>
            <hr></hr>
            <Typography variant="caption">({description})</Typography>
          </Stack>
        </>
      ),
    },
    {
      render: (_, { id }) => (
        <>
          <IconButton
            sx={{ top: "-5px", left: "2px", padding: "0 12px" }}
            variant="text"
            color="error"
            size="small"
            fullwidth
            onClick={(e) => {
              handleDeleteItems(id);
            }}
          >
            <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <Container
      style={{
        justifyContent: "right",
      }}
    >
      <ModalItemService
        isOpen={modal.addItems}
        onClose={() => setModal({ ...data, addItems: false })}
        onChange={handleAddItems}
      ></ModalItemService>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6} lg={6} margin="0 auto">
          <div style={{ marginBottom: 10 }}>
            {status === 0 && (
              <Chip
                style={{ margin: "0 auto" }}
                size=""
                color="warning"
                label="Proses"
              />
            )}
            {status === 1 && (
              <Chip
                style={{ margin: "0 auto" }}
                size=""
                color="primary"
                label="Selesai"
              />
            )}
          </div>
          <Card>
            <CardContent>
              <Typography variant="body">ID Barang</Typography>
              <Stack sx={{ p: 1 }}>
                <Typography variant="body2">{id_barang}</Typography>
              </Stack>
              <Typography variant="body">Detail Barang </Typography>
              <Stack sx={{ p: 1 }}>
                <Typography variant="body2">{nama_barang}</Typography>
              </Stack>
            </CardContent>
          </Card>
          <Stack direction="row" style={{ marginTop: "20px" }}>
            <Typography gutterBottom variant="body" component="div">
              Item Service
            </Typography>
            <IconButton
              sx={{ top: "-5px", left: "2px", padding: "0 12px" }}
              variant="text"
              color="error"
              size="small"
              fullwidth
              onClick={(e) => {
                setModal({ ...data, addItems: true });
              }}
            >
              <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
            </IconButton>
          </Stack>

          <Card>
            <CardContent>
              <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                // loading={isLoading}
              />
            </CardContent>
            <CardContent>
              <Typography gutterBottom variant="body" component="div">
                Keuntungan : Rp.{" "}
                {total_item
                  ? total_item
                      .toLocaleString("id-ID", 0)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                  : 0}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ServicesDetail;
