import React from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { Grid, Modal } from "@mui/material";

function ModalDelete({ isOpen, onClose, onDelete }) {
  if (!isOpen) return null;

  const handleDelete = () => {
    onDelete();
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 300,
          p: 2,
          textAlign: "center",
        }}
      >
        <Typography id="modal-modal-title" variant="h6" component="h3">
          KONFIRMASI
        </Typography>
        <FontAwesomeIcon
          icon={faTrashCan}
          style={{ fontSize: "50px", marginTop: "20px" }}
        />
        <Typography id="modal-modal-description" sx={{ mt: 4 }}>
          Anda yakin ingin menghapus
        </Typography>

        <Grid container spacing={1} sx={{ mt: 1 }}>
          <Grid item xs={6}>
            <Button variant="outlined" onClick={onClose} fullWidth>
              TIDAK
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" onClick={handleDelete} fullWidth>
              IYA
            </Button>
          </Grid>
        </Grid>
      </Card>
    </Modal>
  );
}

export default ModalDelete;
