import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import api from "../ApiDashboard";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo_3 from "../../img/logo-3.png";
import Paper from "@mui/material/Paper";
import {
  Container,
  Box,
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  Divider,
  IconButton,
  Stack,
  TextField,
  InputAdornment,
  MenuItem,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
} from "@mui/material";
import Slider from "react-slick";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TableComponent from "../../components/table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa";
import { MutatingDots } from "react-loader-spinner";
import { Pagination, Table, Spin } from "antd";
import { styled } from "@mui/material/styles";
import {
  faAdd,
  faCamera,
  faImage,
  faPlus,
  faTimes,
  faTimesRectangle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import RadioGroupContext from "@mui/material/RadioGroup/RadioGroupContext";
import { useRef } from "react";
import { DateField, LocalizationProvider } from "@mui/x-date-pickers";
import { NumericFormat } from "react-number-format";

const SubsidiDetail = () => {
  const token = localStorage.getItem("Mitra-Token");
  const navigate = useNavigate();
  const [status, setStatus] = useState(0);
  const [cour, setCour] = useState("");
  const [courSubsidi, setCourSubsidi] = useState("");
  const [other, setOther] = useState("");
  const [otherSubsidi, setOtherSubsidi] = useState("");
  const [gambarOther, setGambarOther] = useState(null);
  const [gambarCour, setGambarCour] = useState(null);
  const [imageCourUrl, setImageCourUrl] = useState(null);
  const [imageOtherUrl, setImageOtherUrl] = useState(null);
  const [detail, setDetail] = useState(false);
  const params = useParams();
  const location = useLocation();

  const fetchData = async () => {
    try {
      const response = await api.get(
        "v1/dashboard/subsidi/detail?id=" + params.id,
        {
          headers: {
            "Mitra-Token": token,
          },
        }
      );
      return response.data.data;
    } catch (error) {
      throw error;
    }
  };
  if (params.id) {
    useEffect(() => {
      setDetail(true);
      const fetchDataAndStopLoading = async () => {
        try {
          const data = await fetchData();
          if (data.status !== 0) {
            setStatus(data.status_subsidi);
            setOther(data.other_name);
            setOtherSubsidi(data.other_subsidi);
            setCour(data.cour_name);
            setCourSubsidi(data.courier_subsidi);
            setImageOtherUrl(data.other_image);
            setImageCourUrl(data.courier_image);
          }
        } catch (error) {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            toast.error(error.response.data.message);
          } else if (error.message) {
            toast.error(error.message);
          } else {
            toast.error("An error occurred. Please try again later.");
          }
        }
      };
      fetchDataAndStopLoading();
    }, []);
  } else {
  }

  const hapusGambar = (type) => async () => {
    try {
      const response = await api.delete(
        "v1/dashboard/subsidi/gambar?id=" + params.id + "&type=" + type,
        {
          headers: {
            "Mitra-Token": token,
          },
        }
      );
      const resData = response.data;
      if (resData.status == "success") {
        toast.success(resData.message);
        const data = await fetchData();
        if (type === "other") {
          setImageOtherUrl(null);
        } else {
          setImageCourUrl(null);
        }
        toast.success(response.data.message);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("An error occurred. Please try again later.");
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    //-----------------data gadai-----------
    if (gambarCour) {
      formData.append("courier_image", gambarCour);
    }
    if (gambarOther) {
      formData.append("other_image", gambarOther);
    }
    //--------------------------------------
    if (detail) {
      formData.append("id", params.id);
      api
        .post("v1/dashboard/subsidi/update", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            "Mitra-Token": token,
          },
        })
        .then((response) => {
          const { status, message, data } = response.data;
          if (status === "success") {
            toast.success(response.data.message);
            navigate("/dashboard/gadai");
          }
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            toast.error(error.response.data.message);
          } else if (error.message) {
            toast.error(error.message);
          } else {
            toast.error("An error occurred. Please try again later.");
          }
        });
    }
  };
  const handleImageCourChange = async (e) => {
    const files = e.target.files;
    setGambarCour(files[0]);
    for (let i = 0; i < files.length; i++) {
      const reader = new FileReader();
      reader.readAsDataURL(files[i]);
      reader.onload = () => {
        setImageCourUrl(reader.result);
      };
    }
    try {
      const formData = new FormData();
      formData.append("image", files[0]);
      formData.append("id", params.id);
      formData.append("jenis", "cour");

      const response = await api.post("v1/dashboard/subsidi/gambar", formData, {
        headers: {
          "Mitra-Token": token,
        },
      });
      const resData = response.data;
      if (resData.status == "success") {
        toast.success(resData.message);
        const data = await fetchData();
        setImageUrl(data.image);
        toast.success(response.data.message);
      }
      // const response = await tambahGambar();
    } catch (error) {
      toast.error(error);
    }
  };
  const handleImageOtherChange = async (e) => {
    const files = e.target.files;
    setGambarOther(files[0]);
    for (let i = 0; i < files.length; i++) {
      const reader = new FileReader();
      reader.readAsDataURL(files[i]);
      reader.onload = () => {
        setImageOtherUrl(reader.result);
      };
    }
    try {
      const formData = new FormData();
      formData.append("image", files[0]);
      formData.append("id", params.id);
      formData.append("jenis", "other");

      const response = await api.post("v1/dashboard/subsidi/gambar", formData, {
        headers: {
          "Mitra-Token": token,
        },
      });
      const resData = response.data;
      if (resData.status == "success") {
        toast.success(resData.message);
        const data = await fetchData();
        setImageUrl(data.image.split(";"));
        toast.success(response.data.message);
      }
      // const response = await tambahGambar();
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <Container
      style={{
        justifyContent: "right",
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={12} md={6} lg={6} margin="0 auto">
          {status === 0 && (
            <Chip
              style={{ marginBottom: "5px" }}
              size=""
              color="warning"
              label="Proses"
            />
          )}
          {status === 1 && (
            <Chip
              style={{ marginBottom: "5px" }}
              size=""
              color="primary"
              label="Diberikan"
            />
          )}
          {status === 2 && (
            <Chip
              style={{ marginBottom: "5px" }}
              color="primary"
              label="Lunas"
            />
          )}
          <Card>
            <form onSubmit={handleSubmit}>
              <CardContent>
                <input
                  type="file"
                  accept="image/*"
                  id="other-image"
                  style={{ display: "none" }}
                  onChange={(e) => {
                    handleImageOtherChange(e);
                  }}
                ></input>
                <input
                  type="file"
                  accept="image/*"
                  id="cour-image"
                  style={{ display: "none" }}
                  onChange={(e) => {
                    handleImageCourChange(e);
                  }}
                ></input>
                <Stack
                  spacing={{ xs: 1, sm: 2 }}
                  direction="row"
                  useFlexGap
                  flexWrap="wrap"
                >
                  {imageOtherUrl ? (
                    <Card
                      style={{
                        flex: "0 0 auto",
                        width: 150,
                        boxShadow: "none",
                        borderRadius: "5px",
                        marginTop: "20px",
                        marginBottom: "20px",
                      }}
                    >
                      <CardMedia
                        component="img"
                        height="150"
                        image={imageOtherUrl}
                        onClick={() => {
                          document.getElementById("other-image").click();
                        }}
                      />
                      <CardContent sx={{ p: 0 }}>
                        <center>
                          <Button
                            variant="text"
                            size="small"
                            startIcon={
                              <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
                            }
                            onClick={hapusGambar("other")}
                          ></Button>
                        </center>
                      </CardContent>
                    </Card>
                  ) : (
                    <Card
                      onClick={() => {
                        document.getElementById("other-image").click();
                      }}
                      style={{
                        flex: "0 0 auto",
                        padding: "45px 10px 30px 10px",
                        width: 150,
                        boxShadow: "none",
                        border: "dashed 1px red",
                        borderRadius: "5px",
                        marginTop: "20px",
                        marginBottom: "20px",
                      }}
                    >
                      <CardMedia>
                        <center>
                          <FontAwesomeIcon
                            icon={faCamera}
                            style={{
                              fontSize: "20px",
                              color: "red",
                              marginBottom: "10px",
                            }}
                          ></FontAwesomeIcon>
                        </center>
                      </CardMedia>
                      <CardContent
                        height="100"
                        style={{ padding: "5px 10px 10px 10px " }}
                      >
                        <center>
                          <Typography variant="caption" color="red" style={{}}>
                            Foto Bukti Seeker / Seller
                          </Typography>
                        </center>
                      </CardContent>
                    </Card>
                  )}
                  {imageCourUrl ? (
                    <Card
                      style={{
                        flex: "0 0 auto",
                        width: 150,
                        boxShadow: "none",
                        borderRadius: "5px",
                        marginTop: "20px",
                        marginBottom: "20px",
                      }}
                    >
                      <CardMedia
                        component="img"
                        height="150"
                        image={imageCourUrl}
                        onClick={() => {
                          document.getElementById("cour-image").click();
                        }}
                      />
                      <CardContent style={{ padding: "0" }}>
                        <center>
                          <Button
                            variant="text"
                            size="small"
                            startIcon={
                              <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
                            }
                            onClick={hapusGambar("cour")}
                          ></Button>
                        </center>
                      </CardContent>
                    </Card>
                  ) : (
                    <Card
                      onClick={() => {
                        document.getElementById("cour-image").click();
                      }}
                      style={{
                        flex: "0 0 auto",
                        padding: "45px 10px 30px 10px",
                        width: 150,
                        boxShadow: "none",
                        border: "dashed 1px red",
                        borderRadius: "5px",
                        marginTop: "20px",
                        marginBottom: "20px",
                      }}
                    >
                      <CardMedia>
                        <center>
                          <FontAwesomeIcon
                            icon={faCamera}
                            style={{
                              fontSize: "20px",
                              color: "red",
                              marginBottom: "10px",
                            }}
                          ></FontAwesomeIcon>
                        </center>
                      </CardMedia>
                      <CardContent
                        height="100"
                        style={{ padding: "5px 10px 10px 10px " }}
                      >
                        <center>
                          <Typography variant="caption" color="red" style={{}}>
                            Foto Bukti Kurir
                          </Typography>
                        </center>
                      </CardContent>
                    </Card>
                  )}
                </Stack>
                <Typography variant="caption">Seller / Seeker </Typography>
                <Stack sx={{ p: 1 }}>
                  <Typography variant="body">{other}</Typography>
                  <Typography variant="body1">
                    Rp.{" "}
                    {otherSubsidi
                      ? otherSubsidi.toLocaleString()
                      : otherSubsidi}
                  </Typography>
                </Stack>
                <Typography variant="caption">Kurir </Typography>
                <Stack sx={{ p: 1 }}>
                  <Typography variant="body">{cour}</Typography>
                  <Typography variant="body1">
                    Rp.{" "}
                    {courSubsidi ? courSubsidi.toLocaleString() : courSubsidi}
                  </Typography>
                </Stack>
              </CardContent>
            </form>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SubsidiDetail;
