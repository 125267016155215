import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
//
import Header from "./headerDashboard";
import Sidebar from "./sidebar/";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../pages/ApiDashboard";

// ----------------------------------------------------------------------
const APP_BAR_MOBILE = 50;
const APP_BAR_DESKTOP = 60;

const StyledRoot = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const Main = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE + 34,
  paddingBottom: theme.spacing(1),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  return (
    <StyledRoot>
      <Header onOpenNav={() => setOpen(true)} />
      <Sidebar openNav={open} onCloseNav={() => setOpen(false)} />

      <Main>
        <Outlet />
      </Main>
    </StyledRoot>
  );
}
