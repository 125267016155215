import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faBox,
  faDownLong,
  faUpLong,
  faHome,
  faTruckArrowRight,
  faReceipt,
  faScaleBalanced,
  faHandshakeAngle,
  faToolbox,
  faGear,
} from "@fortawesome/free-solid-svg-icons";
const navConfig = [
  {
    title: "Beranda",
    path: "/dashboard/",
    icon: <FontAwesomeIcon icon={faHome} />,
  },
  {
    title: "Barang",
    path: "/dashboard/barang",
    icon: <FontAwesomeIcon icon={faBox} />,
  },
  {
    title: "Transaksi",
    path: "/dashboard/transaksi/",
    icon: <FontAwesomeIcon icon={faReceipt} />,
  },
  {
    title: "Barang Transfer",
    path: "/dashboard/transfer",
    icon: <FontAwesomeIcon icon={faTruckArrowRight} />,
  },
  {
    title: "Gadai",
    path: "/dashboard/gadai",
    icon: <FontAwesomeIcon icon={faScaleBalanced} />,
  },
  {
    title: "Subsidi",
    path: "/dashboard/subsidi",
    icon: <FontAwesomeIcon icon={faHandshakeAngle} />,
  },
  {
    title: "Services",
    path: "/dashboard/services",
    icon: <FontAwesomeIcon icon={faToolbox} />,
  },
  {
    title: "Pengaturan Lainnya",
    path: "/dashboard/settings",
    icon: <FontAwesomeIcon icon={faGear} />,
  },
];

export default navConfig;
