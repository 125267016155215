import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import api from "../ApiDashboard";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo_3 from "../../img/logo-3.png";
import Paper from "@mui/material/Paper";
import {
  Container,
  Box,
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  Divider,
  IconButton,
  Stack,
  TextField,
  InputAdornment,
  MenuItem,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
} from "@mui/material";
import Slider from "react-slick";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TableComponent from "../../components/table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa";
import { MutatingDots } from "react-loader-spinner";
import { Pagination, Table, Spin } from "antd";
import { styled } from "@mui/material/styles";
import {
  faAdd,
  faCamera,
  faImage,
  faPlus,
  faTimes,
  faTimesRectangle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import RadioGroupContext from "@mui/material/RadioGroup/RadioGroupContext";
import { useRef } from "react";
import { DateField, LocalizationProvider } from "@mui/x-date-pickers";
import { NumericFormat } from "react-number-format";
import dayjs from "dayjs";

const GadaiDetail = () => {
  const token = localStorage.getItem("Mitra-Token");
  const navigate = useNavigate();
  const [member, setMember] = useState("");
  const [status, setStatus] = useState(0);
  const [total_bayar, setTotalBayar] = useState("");
  const [totalHari, setTotalHari] = useState("");
  const [jatuhTempo, setJatuhTempo] = useState("");
  const [total_today, setTotalToday] = useState("");
  const [totalDays, setTotalDays] = useState("");
  const [gambarKtp, setGambarKtp] = useState(null);
  const [imageUrl, setImageUrl] = useState([]);
  const [imageKtpUrl, setImageKtpUrl] = useState(null);
  const [seeker, setSeeker] = useState("");
  const fileInputRef = useRef(null);

  const [kurir, setKurir] = useState("");
  const [data_account, setData] = useState([]);
  const [data_account_loaded, setDataLoaded] = useState(false);
  const [detail, setDetail] = useState(false);
  const params = useParams();
  //---------------------param gadai---------------
  const [nik, setNik] = useState("");
  const [phone, setPhone] = useState("");
  const [nama_penggadai, setNamaPenggadai] = useState("");
  const [alamat, setAlamat] = useState("");
  const [biaya_titip, setBiayaTitip] = useState("");
  const [tanggal_mulai, setTanggalMulai] = useState(null);
  const [lama_hari, setLamaHari] = useState("");
  const [total_pinjaman, setTotalPinjaman] = useState("");
  const [mht, setMht] = useState("");
  const [keterangan, setKeterangan] = useState("");
  const [waktu_lelang, setWaktuLelang] = useState("");
  //------barang---------
  const [no_seri, setNoSeri] = useState("");
  const [category, setKategori] = useState("");
  const [name, setName] = useState("");

  const fetchData = async () => {
    try {
      const response = await api.get(
        "v1/dashboard/gadai/detail?id=" + params.id,
        {
          headers: {
            "Mitra-Token": token,
          },
        }
      );
      return response.data.data;
    } catch (error) {
      throw error;
    }
  };
  if (params.id) {
    useEffect(() => {
      setDetail(true);
      const fetchDataAndStopLoading = async () => {
        try {
          const data = await fetchData();
          setKategori(data.category);
          setNoSeri(data.no_seri);
          setName(data.barang_name);
          if (data.status !== 0) {
            setMember(data.member);
            setNik(data.nik);
            setPhone(data.phone);
            setNamaPenggadai(data.name);
            setAlamat(data.address);
            setBiayaTitip(data.gadai_fee);
            setTanggalMulai(data.start_date);
            setImageKtpUrl(data.ktp_image);
            setLamaHari(data.days);
            setTotalPinjaman(data.total_amount);
            setMht(data.mht);
            setKeterangan(data.description);
            setWaktuLelang(data.exc_days);
            setTotalHari(data.totalHari);
            setTotalToday(data.total_today);
            setJatuhTempo(data.jatuhTempo);
            setStatus(data.status);
            setTotalDays(data.total_days);
            setTotalBayar(data.total);
            //---------barang-------------
          }
        } catch (error) {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            toast.error(error.response.data.message);
          } else if (error.message) {
            toast.error(error.message);
          } else {
            toast.error("An error occurred. Please try again later.");
          }
        }
      };
      fetchDataAndStopLoading();
    }, []);
  } else {
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    //-----------------data gadai-----------
    formData.append("nik", nik);
    if (gambarKtp) {
      formData.append("foto_ktp", gambarKtp);
    }
    formData.append("phone", phone);
    formData.append("nama_penggadai", nama_penggadai);
    formData.append("alamat", alamat);
    formData.append("biaya_titip", biaya_titip);
    const formattedDate = dayjs(tanggal_mulai).format("YYYY-MM-DD");
    formData.append("tanggal_mulai", formattedDate);
    formData.append("lama_hari", lama_hari);
    formData.append("total_pinjaman", total_pinjaman);
    formData.append("mht", mht);
    formData.append("keterangan", keterangan);
    formData.append("waktu_lelang", waktu_lelang);
    //--------------------------------------
    if (detail) {
      formData.append("id", params.id);
      api
        .post("v1/dashboard/gadai/update", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            "Mitra-Token": token,
          },
        })
        .then((response) => {
          const { status, message, data } = response.data;
          if (status === "success") {
            toast.success(response.data.message);
            navigate("/dashboard/gadai");
          }
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            toast.error(error.response.data.message);
          } else if (error.message) {
            toast.error(error.message);
          } else {
            toast.error("An error occurred. Please try again later.");
          }
        });
    }
  };
  const handleImageKtpChange = async (e) => {
    const files = e.target.files;
    setGambarKtp(files[0]);
    for (let i = 0; i < files.length; i++) {
      const reader = new FileReader();
      reader.readAsDataURL(files[i]);
      reader.onload = () => {
        setImageKtpUrl(reader.result);
      };
    }
  };

  return (
    <Container
      style={{
        justifyContent: "right",
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={12} md={6} lg={6} margin="0 auto">
          <Card>
            <form onSubmit={handleSubmit}>
              <CardContent>
                {status === 0 && (
                  <Chip
                    style={{ margin: "0 auto" }}
                    size=""
                    color="error"
                    label="Tunda"
                  />
                )}
                {status === 1 && (
                  <Chip
                    style={{ margin: "0 auto" }}
                    size=""
                    color="warning"
                    label="Berjalan"
                  />
                )}
                {status === 2 && (
                  <Chip
                    style={{ margin: "auto auto" }}
                    color="primary"
                    label="Lunas"
                  />
                )}

                <Stack sx={{ pl: 3, pr: 3, pt: 1, pb: 3 }}>
                  <Typography variant="caption" sx={{ mt: 1, mb: 0.5 }}>
                    Info Barang
                  </Typography>
                  <Stack sx={{ m: "0 auto" }}>
                    <Typography variant="body2">
                      {category} - {name}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{ color: "rgba(0,0,0,0.4)" }}
                    >
                      {no_seri}
                    </Typography>
                  </Stack>
                  {status === 1 && (
                    <>
                      <Typography variant="caption" sx={{ mt: 2, mb: 0.2 }}>
                        Info Gadai
                      </Typography>
                      <Box sx={{ m: "0 auto" }}>
                        <table>
                          <tr>
                            <td>
                              <Typography variant="body2">
                                Hari gadai
                              </Typography>
                            </td>
                            <td>:</td>
                            <td>{totalHari} Hari</td>
                          </tr>
                          <tr>
                            <td>
                              <Typography variant="body2">
                                Total Biaya Titip
                              </Typography>
                            </td>
                            <td>:</td>
                            <td>
                              <NumericFormat
                                value={total_today}
                                prefix="Rp. "
                                thousandSeparator="."
                                decimalSeparator=","
                                displayType="text"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Typography variant="body2">
                                Jatuh Tempo
                              </Typography>
                            </td>
                            <td>:</td>
                            <td>{jatuhTempo}</td>
                          </tr>
                        </table>
                      </Box>
                    </>
                  )}
                  {status === 2 && (
                    <>
                      <Typography variant="caption" sx={{ mt: 2, mb: 0.2 }}>
                        Info Gadai
                      </Typography>
                      <Box sx={{ m: "0 auto" }}>
                        <table>
                          <tr>
                            <td>
                              <Typography variant="body2">
                                Total Hari Gadai
                              </Typography>
                            </td>
                            <td>:</td>
                            <td>{totalDays} Hari</td>
                          </tr>
                          <tr>
                            <td>
                              <Typography variant="body2">
                                Total Dibayar
                              </Typography>
                            </td>
                            <td>:</td>
                            <td>
                              <NumericFormat
                                value={total_bayar}
                                prefix="Rp. "
                                thousandSeparator="."
                                decimalSeparator=","
                                displayType="text"
                              />
                            </td>
                          </tr>
                        </table>
                      </Box>
                    </>
                  )}
                </Stack>
                <Typography variant="caption" sx={{ m: 3 }}>
                  Data Penggadai
                </Typography>
                <input
                  type="file"
                  accept="image/*"
                  id="ktp-image"
                  style={{ display: "none" }}
                  onChange={(e) => {
                    handleImageKtpChange(e);
                  }}
                ></input>
                {imageKtpUrl ? (
                  <Card
                    onClick={() => {
                      document.getElementById("ktp-image").click();
                    }}
                    style={{
                      flex: "0 0 auto",
                      width: 150,
                      boxShadow: "none",
                      borderRadius: "5px",
                      marginTop: "20px",
                      marginBottom: "20px",
                    }}
                  >
                    <CardMedia
                      component="img"
                      height="150"
                      image={imageKtpUrl}
                    />
                  </Card>
                ) : (
                  <Card
                    onClick={() => {
                      document.getElementById("ktp-image").click();
                    }}
                    style={{
                      flex: "0 0 auto",
                      padding: "45px 10px 30px 10px",
                      width: 150,
                      boxShadow: "none",
                      border: "dashed 1px red",
                      borderRadius: "5px",
                      marginTop: "20px",
                      marginBottom: "20px",
                    }}
                  >
                    <CardMedia>
                      <center>
                        <FontAwesomeIcon
                          icon={faCamera}
                          style={{
                            fontSize: "20px",
                            color: "red",
                            marginBottom: "10px",
                          }}
                        ></FontAwesomeIcon>
                      </center>
                    </CardMedia>
                    <CardContent
                      height="100"
                      style={{ padding: "5px 10px 10px 10px " }}
                    >
                      <center>
                        <Typography variant="caption" color="red" style={{}}>
                          Foto KTP
                        </Typography>
                      </center>
                    </CardContent>
                  </Card>
                )}

                <TextField
                  label="NIK"
                  sx={{ mb: 2 }}
                  fullWidth
                  size="small"
                  value={nik}
                  onChange={(e) => {
                    setNik(e.target.value);
                  }}
                ></TextField>
                <TextField
                  label="Nama Penggadai"
                  sx={{ mb: 2 }}
                  fullWidth
                  size="small"
                  value={nama_penggadai}
                  onChange={(e) => {
                    setNamaPenggadai(e.target.value);
                  }}
                ></TextField>
                <TextField
                  label="No HP"
                  sx={{ mb: 2 }}
                  fullWidth
                  size="small"
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                ></TextField>
                <TextField
                  variant="outlined"
                  size="small"
                  value={alamat}
                  onChange={(event) => {
                    setAlamat(event.target.value);
                  }}
                  rows={4}
                  fullWidth
                  multiline
                  sx={{ mb: 2 }}
                  label="Alamat"
                ></TextField>
                <Typography variant="caption" sx={{ mb: 3 }}>
                  Data Lainya
                </Typography>
                <TextField
                  label="Biaya Titip"
                  sx={{ mb: 2, mt: 2 }}
                  fullWidth
                  size="small"
                  value={biaya_titip}
                  onChange={(e) => {
                    setBiayaTitip(e.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography variant="caption">Rp </Typography>
                      </InputAdornment>
                    ),
                  }}
                ></TextField>
                <TextField
                  label="Total Pinjaman"
                  sx={{ mb: 2 }}
                  fullWidth
                  size="small"
                  value={total_pinjaman}
                  onChange={(e) => {
                    setTotalPinjaman(e.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography variant="caption">Rp </Typography>
                      </InputAdornment>
                    ),
                  }}
                ></TextField>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    fullWidth
                    label="Tanggal Mulai"
                    size="small"
                    sx={{ mb: 2 }}
                    value={dayjs(tanggal_mulai)}
                    onChange={(e) => {
                      setTanggalMulai(e);
                    }}
                    slotProps={{ textField: { size: "small" } }}
                    format="DD-MM-YYYY"
                  />
                </LocalizationProvider>
                <TextField
                  label="Lama Hari"
                  sx={{ mb: 2 }}
                  fullWidth
                  size="small"
                  value={lama_hari}
                  onChange={(e) => {
                    setLamaHari(e.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography variant="caption">Hari</Typography>
                      </InputAdornment>
                    ),
                  }}
                ></TextField>
                <TextField
                  label="Minimum Hari Tebus"
                  sx={{ mb: 2 }}
                  fullWidth
                  size="small"
                  value={mht}
                  onChange={(e) => {
                    setMht(e.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography variant="caption">Hari</Typography>
                      </InputAdornment>
                    ),
                  }}
                ></TextField>
                <TextField
                  label="Waktu Lelang"
                  sx={{ mb: 2 }}
                  fullWidth
                  size="small"
                  value={waktu_lelang}
                  onChange={(e) => {
                    setWaktuLelang(e.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography variant="caption">Hari</Typography>
                      </InputAdornment>
                    ),
                  }}
                ></TextField>
                <TextField
                  variant="outlined"
                  size="small"
                  value={keterangan}
                  onChange={(event) => {
                    setKeterangan(event.target.value);
                  }}
                  rows={4}
                  fullWidth
                  multiline
                  label="Keterangan"
                ></TextField>

                <Button
                  onClick={handleSubmit}
                  size="small"
                  variant="contained"
                  fullWidth
                  sx={{ mt: 2 }}
                >
                  Simpan
                </Button>
              </CardContent>
            </form>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default GadaiDetail;
