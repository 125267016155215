import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import api from "../../pages/ApiDashboard";
import Button from "@mui/material/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashCan,
  faTruckArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { Grid, Modal, TextField } from "@mui/material";

function ModalPassword({ isOpen, onClose, onChange }) {
  if (!isOpen) return null;
  const [data, setData] = useState({
    password: "",
    status: false,
  });
  const token = localStorage.getItem("Mitra-Token");

  const handleVerifiedPassword = async () => {
    const response = await api.post(
      `v1/verified_password`,
      { password: data.password },
      {
        headers: { "Mitra-Token": token },
      }
    );
    if (response) {
      if (response.data.status == "success") {
        onChange(true);
        onClose();
      } else {
        onChange(false);
        onClose();
      }
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 300,
          p: 2,
          textAlign: "center",
        }}
      >
        <Typography id="modal-modal-title" variant="h6" component="h3">
          KONFIRMASI KATA SANDI
        </Typography>

        <Grid container spacing={1} sx={{ mt: 1 }}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={data.password}
              onChange={(e) => {
                setData({
                  ...data,
                  password: e.target.value,
                });
              }}
              sx={{ mb: 2 }}
              label="Kata Sandi"
            ></TextField>
          </Grid>
          <Grid item xs={6}>
            <Button variant="outlined" onClick={onClose} fullWidth>
              BATAL
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              onClick={handleVerifiedPassword}
              fullWidth
            >
              KONFIRMASI
            </Button>
          </Grid>
        </Grid>
      </Card>
    </Modal>
  );
}

export default ModalPassword;
