import React, { Component } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import api from "../../pages/Api";

const style = {
  height: 30,
  border: "1px solid green",
  margin: 6,
  padding: 8,
};

class InfiniteScrollList extends React.Component {
  //   constructor() {
  //     super();
  //     this.state = {
  //       items: [],
  //       page: 1,
  //       hasMore: true,
  //       isLoading: false,
  //     };
  //   }
  state = {
    items: [],
    page: 1,
    url: "barang?page=1",
  };

  //   componentDidMount() {
  //     this.fetchData();
  //   }

  // fetchData = () => {
  //   if (this.state.isLoading) return;

  //   this.setState({ isLoading: true });

  //   api
  //     .get(`barang?page=${this.state.page}`)
  //     .then((response) => {
  //       const newData = response.data.data.data;
  //       setTimeout(() => {
  //         this.setState((prevState) => ({
  //           items: prevState.items.concat(newData),
  //           page: prevState.page + 1,
  //           hasMore: newData.length > 0,
  //           isLoading: false,
  //         }));
  //       }, 5000);

  //       console.log(this.state.items.length);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching data:", error);
  //       this.setState({ isLoading: false });
  //     });
  // };
  fetchMoreData = () => {
    setTimeout(() => {
      api
        .get(this.state.url)
        .then((response) => {
          const newData = response.data.data;
          if (newData.next_page_url === null) {
            this.setState({
              hasMore: false,
            });
          }
          setTimeout(() => {
            this.setState({
              url: newData.next_page_url,
            });
          }, 5000);

          console.log(this.state.items.length);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          this.setState({ isLoading: false });
        });
      this.setState({
        items: this.state.items.concat(Array.from({ length: 10 })),
      });
    }, 1500);
  };

  render() {
    return (
      <InfiniteScroll
        dataLength={this.state.items.length}
        next={this.fetchMoreData}
        hasMore={true}
        loader={<h4>Loading...</h4>}
      >
        {this.state.items.map((i, index) => (
          <div style={style} key={index}>
            div - #{index}
          </div>
        ))}
      </InfiniteScroll>
    );
  }
}

export default InfiniteScrollList;
