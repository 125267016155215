import React, { useState } from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamation, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { Grid, Modal, TextField } from "@mui/material";

function ModalServices({ isOpen, onClose, onChange }) {
  if (!isOpen) return null;
  const [pic, setPic] = useState("");
  const handleServices = () => {
    onChange(pic);
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 300,
          p: 2,
          textAlign: "center",
        }}
      >
        <Typography id="modal-modal-title" variant="h6" component="h3">
          SERVICE BARANG
        </Typography>
        <FontAwesomeIcon
          icon={faExclamation}
          style={{ fontSize: "50px", marginTop: "20px" }}
        />
        <Grid container spacing={1} sx={{ mt: 1 }}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={pic}
              onChange={(e) => {
                setPic(e.target.value);
              }}
              sx={{ mb: 2 }}
              label="Id Member (PIC)"
            ></TextField>
          </Grid>
          <Grid item xs={6}>
            <Button variant="outlined" onClick={onClose} fullWidth>
              BATAL
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" onClick={handleServices} fullWidth>
              PROSES
            </Button>
          </Grid>
        </Grid>
      </Card>
    </Modal>
  );
}

export default ModalServices;
