import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDollarSign,
  faPlus,
  faTrashCan,
  faTruckArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  MenuItem,
  Modal,
  Popover,
  TextField,
} from "@mui/material";
import api from "../../pages/ApiDashboard";
import { toast } from "react-toastify";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

function ModalFilterDate({
  open,
  onClose,
  anchorEl,
  onApplyFilter,
  start,
  end,
  changeStart,
  changeEnd,
}) {
  if (!open) return null;

  const handleFilter = () => {
    onClose();
    onApplyFilter();
  };
  const handleStart = (e) => {
    changeStart(e);
  };
  const handleEnd = (e) => {
    changeEnd(e);
  };
  const removeFilter = () => {
    changeStart(null);
    changeEnd(null);
  };

  return (
    <Popover
      id="filter-menu"
      open={open}
      anchorEl={anchorEl}
      onClose={() => {
        onClose();
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <div style={{ padding: "20px", width: "250px" }}>
        <Typography variant="body" sx={{ fontSize: "14px" }}>
          Filter Tanggal
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker"]}>
            <DatePicker
              label="Tanggal Mulai"
              slotProps={{ textField: { size: "small" } }}
              value={start}
              onChange={handleStart}
              format="DD-MM-YYYY"
              sx={{ mt: 2 }}
            />
          </DemoContainer>
          <DemoContainer components={["DatePicker"]}>
            <DatePicker
              label="Tanggal Selesai"
              slotProps={{ textField: { size: "small" } }}
              value={end}
              onChange={handleEnd}
              format="DD-MM-YYYY"
              sx={{ mt: 2 }}
            />
          </DemoContainer>
        </LocalizationProvider>
        <Button
          fullwidth
          variant="outlined"
          sx={{ mt: 2 }}
          onClick={() => {
            removeFilter();
          }}
        >
          Hilangkan
        </Button>
        <Button
          fullwidth
          variant="contained"
          sx={{ mt: 2, ml: 1 }}
          onClick={() => {
            handleFilter();
          }}
        >
          Terapkan
        </Button>
      </div>
    </Popover>
  );
}

export default ModalFilterDate;
