import PropTypes from "prop-types";
// @mui
import { styled } from "@mui/material/styles";
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  Grid,
  Typography,
} from "@mui/material";
// utils
// components
//
import Searchbar from "./Searchbar";
import { bgBlur } from "../../utils/cssStyles";
import AccountPopover from "./AccountPopover";
import {
  faAngleLeft,
  faList,
  faUserAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useLocation, useNavigate } from "react-router-dom";

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const HEADER_MOBILE = 50;

const HEADER_DESKTOP = 60;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
  [theme.breakpoints.up("lg")]: {
    width: "100%",
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <StyledRoot>
      <StyledToolbar>
        <Grid container>
          <Grid item lg={1} xs={2}>
            {(location.pathname === "/barang" ||
              location.pathname === "/detail/barang") && (
              <>
                <IconButton
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <FontAwesomeIcon
                    icon={faAngleLeft}
                    fontSize={20}
                    color="red"
                  />
                </IconButton>
              </>
            )}
          </Grid>
          <Grid item lg={10} xs={8}>
            <center>
              <img
                src={process.env.REACT_APP_CONTENT_URL + "/profile/logo-1.jpg"}
                style={{
                  width: "auto",
                  objectFit: "contain",
                  height: 30,
                }}
              />
            </center>
          </Grid>
          <Grid item lg={1} xs={2}>
            <Stack
              direction="row"
              alignItems="right"
              spacing={{
                xs: 0.5,
                sm: 1,
                lg: 3,
              }}
              justifyContent="end"
            >
              <Searchbar />
              {location.pathname !== "/barang" &&
                location.pathname !== "/detail/barang" && (
                  <>
                    <AccountPopover />
                  </>
                )}
            </Stack>
          </Grid>
        </Grid>
      </StyledToolbar>
    </StyledRoot>
  );
}
