import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import api from "../ApiDashboard.js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Container,
  Box,
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  Divider,
  CardHeader,
  Paper,
  Popover,
  IconButton,
} from "@mui/material";
import Slider from "react-slick";
import { DashboardCarousel } from "../../components/carousel/Carousel.js";
import { FilterTransaksi } from "../../components/modal/modalAll.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";
import CountUp from "react-countup";
import { useSpring, animated } from "react-spring";
import { DateRangeIcon } from "@mui/x-date-pickers";
const Index = () => {
  const [filter, setFilter] = useState({
    transaksiMasuk: false,
    transaksiKeluar: false,
    anchorMasuk: null,
    anchorKeluar: null,
  });
  const [data, setData] = useState({
    dalamMasuk: "n",
    startMasuk: null,
    endMasuk: null,
    dalamKeluar: "n",
    startKeluar: null,
    endKeluar: null,
  });
  const [totalMasuk, setTotalMasuk] = useState(0);
  const [totalKeluar, setTotalKeluar] = useState(0);
  // const tokenString = localStorage.getItem("Mitra-Token");

  const token = localStorage.getItem("Mitra-Token");
  const navigate = useNavigate();
  useEffect(() => {
    const response = api
      .get("v1/dashboard/tes", {
        headers: { "Mitra-Token": token },
      })
      .then((response) => {})
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else if (error.message) {
          toast.error(error.message);
        } else {
          toast.error("An error occurred. Please try again later.");
        }
      });
  }, [token]);

  const fetchData = async (url) => {
    try {
      const response = await api.get(url, {
        headers: { "Mitra-Token": token },
      });
      return response.data.data;
    } catch (error) {
      throw error;
    }
  };

  const addFilterDateMasuk = async (data, status) => {
    setFilter({ ...filter, anchorMasuk: null });
    let url = `v1/dashboard/transaksi_masuk`;
    url = url + "?dalam=" + data.dalam;
    if (status === "Y") {
      if (data.start) {
        const formattedDate = dayjs(data.start).format("YYYY-MM-DD");
        url = url + "&start=" + formattedDate;
      }
      if (data.end) {
        const formattedDate = dayjs(data.end).format("YYYY-MM-DD");
        url = url + "&end=" + formattedDate;
      }
    }

    try {
      const responseData = await fetchData(url);
      setTotalMasuk(responseData.total);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("An error occurred. Please try again later.");
      }
    }
  };
  const addFilterDateKeluar = async (data, status) => {
    setFilter({ ...filter, anchorKeluar: null });

    let url = `v1/dashboard/transaksi_keluar`;
    url = url + "?dalam=" + data.dalam;

    if (status === "Y") {
      if (data.start) {
        const formattedDate = dayjs(data.start).format("YYYY-MM-DD");
        url = url + "&start=" + formattedDate;
      }
      if (data.end) {
        const formattedDate = dayjs(data.end).format("YYYY-MM-DD");
        url = url + "&end=" + formattedDate;
      }
    }
    try {
      const responseData = await fetchData(url);
      setTotalKeluar(responseData.total);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("An error occurred. Please try again later.");
      }
    }
  };
  useEffect(() => {
    const response = api
      .get("v1/dashboard/transaksi_masuk", {
        headers: { "Mitra-Token": token },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setTotalMasuk(response.data.data.total);
        }
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else if (error.message) {
          toast.error(error.message);
        } else {
          toast.error("An error occurred. Please try again later.");
        }
      });
  }, []);
  useEffect(() => {
    const response = api
      .get("v1/dashboard/transaksi_keluar", {
        headers: { "Mitra-Token": token },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setTotalKeluar(response.data.data.total);
        }
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else if (error.message) {
          toast.error(error.message);
        } else {
          toast.error("An error occurred. Please try again later.");
        }
      });
  }, []);

  const item = [
    { quarter: 1, earnings: 13000 },
    { quarter: 2, earnings: 16500 },
    { quarter: 3, earnings: 14250 },
    { quarter: 4, earnings: 19000 },
  ];
  return (
    <>
      <Container
        style={{
          justifyContent: "right",
        }}
      >
        <FilterTransaksi
          open={Boolean(filter.anchorMasuk)}
          anchorEl={filter.anchorMasuk}
          onClose={(e) => {
            setFilter({ ...filter, anchorMasuk: null });
          }}
          onApplyFilter={addFilterDateMasuk}
          dalam={data.dalamMasuk}
          start={data.startMasuk}
          end={data.endMasuk}
        ></FilterTransaksi>
        <FilterTransaksi
          open={Boolean(filter.anchorKeluar)}
          anchorEl={filter.anchorKeluar}
          onClose={(e) => {
            setFilter({ ...filter, anchorKeluar: null });
          }}
          onApplyFilter={addFilterDateKeluar}
          dalam={data.dalamKeluar}
          start={data.startKeluar}
          end={data.endKeluar}
        ></FilterTransaksi>
        <Grid container spacing={2}>
          <DashboardCarousel />
          <Grid item xs={12} md={4} lg={4}>
            <Card>
              <CardContent sx={{ padding: "5px 10px 20px 20px" }}>
                <Grid container>
                  <Grid item xs={8} md={8} lg={8}>
                    <Typography gutterBottom variant="caption" component="div">
                      Pembelian
                    </Typography>
                  </Grid>
                  <Grid item xs={4} md={4} lg={4}>
                    <Box display="flex" justifyContent="flex-end">
                      <IconButton
                        sx={{ right: 0 }}
                        variant="text"
                        color="error"
                        size="small"
                        fullwidth
                        onClick={(e) => {
                          setFilter({
                            ...filter,
                            anchorMasuk: e.currentTarget,
                          });
                        }}
                      >
                        <FontAwesomeIcon icon={faFilter}></FontAwesomeIcon>
                      </IconButton>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <Typography variant="h5">
                      Rp{" "}
                      {totalMasuk
                        .toLocaleString("id-ID", 0)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Card>
              <CardContent sx={{ padding: "5px 10px 20px 20px" }}>
                <Grid container>
                  <Grid item xs={8} md={8} lg={8}>
                    <Typography gutterBottom variant="caption" component="div">
                      Penjualan
                    </Typography>
                  </Grid>
                  <Grid item xs={4} md={4} lg={4}>
                    <Box display="flex" justifyContent="flex-end">
                      <IconButton
                        sx={{ right: 0 }}
                        variant="text"
                        color="error"
                        size="small"
                        fullwidth
                        onClick={(e) => {
                          setFilter({
                            ...filter,
                            anchorKeluar: e.currentTarget,
                          });
                        }}
                      >
                        <FontAwesomeIcon icon={faFilter}></FontAwesomeIcon>
                      </IconButton>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <Typography variant="h5">
                      Rp{" "}
                      {totalKeluar
                        .toLocaleString("id-ID", 0)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Card>
              <CardContent sx={{ padding: "5px 10px 20px 20px" }}>
                <Grid container>
                  <Grid item xs={8} md={8} lg={8}>
                    <Typography gutterBottom variant="caption" component="div">
                      Laba
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <Typography variant="h5">
                      Rp{" "}
                      {(totalKeluar - totalMasuk)
                        .toLocaleString("id-ID", 0)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Index;
