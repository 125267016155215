import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Grid } from "@mui/material";
import logo_3 from "../../img/logo-3.png";
import logo_4 from "../../img/logo-4.png";
import logo_5 from "../../img/logo-5.png";
import Slider from "react-slick";
import axios from "axios";

var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  autoplay: true,
  autoplaySpeed: 3000,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true,
  pauseOnHover: true,
  accessbility: false,
  responsive: [
    {
      breakpoint: 180,
      settings: {},
    },
  ],
};
export function HomeCarousel() {
  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid item xs={12} xl={12} lg={12}>
        <div className="carousel-container">
          <Slider {...settings}>
            <div>
              <img src={logo_3} className="carousel-image" />
            </div>
            <div>
              <img src={logo_3} className="carousel-image" />
            </div>
            <div>
              <img src={logo_3} className="carousel-image" />
            </div>
          </Slider>
        </div>
      </Grid>
    </Grid>
  );
}
export function DashboardCarousel() {
  return (
    <>
      <Grid item xs={0} sm={2} md={2} lg={2}></Grid>

      <Grid item xs={12} sm={8} md={8} lg={8}>
        <div className="carousel-container">
          <Slider {...settings}>
            <div>
              <img src={logo_3} className="carousel-image" />
            </div>
            <div>
              <img src={logo_3} className="carousel-image" />
            </div>
            <div>
              <img src={logo_3} className="carousel-image" />
            </div>
          </Slider>
        </div>
      </Grid>
    </>
  );
}
