import React from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { Grid, Modal } from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import logo_3 from "../../img/logo-3.png";
import Slider from "react-slick";

function ModalImage({ isOpen, onClose, image }) {
  if (!isOpen) return null;

  const handleDelete = () => {
    onDelete();
    onClose();
  };
  var settings = {
    dots: true,
    infinite: false,
    lazyLoad: true,
    speed: 500,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    pauseOnHover: true,
    accessbility: false,
  };
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div style={{ width: "80%", margin: "150px auto" }}>
        <Slider {...settings}>
          {image.map((item, index) => {
            if (item !== "") {
              return (
                <div style={{ backgroundColor: "black" }}>
                  <img
                    src={item}
                    className="carousel-image"
                    style={{
                      objectFit: "contain",
                      width: "100%",
                      height: "300px",
                    }}
                  />
                </div>
              );
            }
          })}
        </Slider>
      </div>
    </Modal>
  );
}

export default ModalImage;
