import axios from "axios";
import UAParser from "ua-parser-js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const parser = new UAParser();
const refresh_token = localStorage.getItem("Refresht-Token");
const userAgentData = parser.getResult();
const browser =
  userAgentData.browser.version + "/" + userAgentData.browser.version;
const device =
  userAgentData.device.model +
  "/" +
  userAgentData.device.model +
  "/" +
  userAgentData.device.vendor;
const os = userAgentData.os.name + "/" + userAgentData.os.version;
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // Ganti dengan base URL API Anda
});
let isRefreshing = false;
const GenerateRefreshToken = (refreshToken) => {
  return api.post("/refresh_token", {
    refresh_token: refreshToken,
    browser: browser,
    device: device,
    os: os,
  });
};

// Tambahkan response interceptor

api.interceptors.response.use(
  (response) => response,
  (error) => {
    const {
      config,
      response: { status },
    } = error;

    const originalRequest = config;

    const refreshToken = localStorage.getItem("Refresh-Token");
    if (status === 401) {
      if (refreshToken !== null) {
        if (!isRefreshing) {
          isRefreshing = true;

          GenerateRefreshToken(refreshToken)
            .then(async (res) => {
              isRefreshing = false;

              const { data } = res;

              localStorage.setItem("Token", data.data.Token);
              return api(originalRequest);
            })
            .catch((error) => {
              localStorage.clear();
              toast.warning("Silahkan masuk kembali!");
              return Promise.reject(error);
            });
        }

        return new Promise((resolve) => {});
      } else {
        // console.log(refreshToken);
        window.location.href = "/";
        localStorage.clear();
        toast.warning("Silahkan masuk kembali!");
        return Promise.reject(error);
      }
    } else if (status != 200) {
      return Promise.reject(error);
    }
  }
);

export default api;
