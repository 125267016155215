import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import api from "../ApiDashboard";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Container,
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  Button,
} from "@mui/material";
import { format } from "date-fns";

const Notification = () => {
  const token = localStorage.getItem("Mitra-Token");

  const fetchData = async (page, rowsPerPage, clearSearch = "N") => {
    try {
      let url = `v1/dashboard/notif?paginate=${page}`;
      const response = await api.get(url, {
        headers: { "Mitra-Token": token },
      });
      return response.data.data;
    } catch (error) {
      throw error;
    }
  };

  const [data, setData] = useState([]);
  const [page, setPage] = useState(10);
  const [total, setTotal] = useState(0);
  useEffect(() => {
    const fetchDataAndStopLoading = async () => {
      try {
        const responseData = await fetchData(page);
        setData(responseData.data);
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else if (error.message) {
          toast.error(error.message);
        } else {
          toast.error("An error occurred. Please try again later.");
        }
      }
    };

    fetchDataAndStopLoading();
  }, [page]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (ids, status) => (event) => {
    setId(ids);

    setStatus(status);
    setAnchorEl(event.currentTarget);
  };

  const markRead = async () => {
    try {
      const response = await api.get(`v1/dashboard/notif/mark_read`, {
        headers: { "Mitra-Token": token },
      });
      const responseData = await fetchData(page);
      setData(responseData.data);
      setTotal(0);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("An error occurred. Please try again later.");
      }
    }
  };
  useEffect(() => {
    const response = api
      .get("v1/dashboard/notif/count", {
        headers: { "Mitra-Token": token },
      })
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === "success") {
          setTotal(data.count);
        }
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.message);
        }
      });
  }, []);

  const excLink = async (link) => {
    try {
      const response = await api.get(link, {
        headers: { "Mitra-Token": token },
      });
      const responseData = await fetchData(page);
      setData(responseData.data);
      setTotal(total - 1);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("An error occurred. Please try again later.");
      }
    }
  };
  return (
    <Container
      style={{
        justifyContent: "right",
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={8} md={8} lg={8}>
          <Typography gutterBottom variant="h6" component="div">
            Notifikasi
          </Typography>
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              variant="text"
              size="small"
              sx={{ fontSize: "10px" }}
              onClick={() => {
                markRead();
              }}
            >
              Tandai dibaca ({total})
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12} lg={12}>
          {data.map((item, index) => {
            return (
              <Card
                xs={12}
                md={10}
                lg={8}
                sx={{
                  marginBottom: "10px",
                }}
              >
                <CardContent
                  sx={{
                    color:
                      item.status == 1 ? "rgba(170,170,170)" : "rgba(0,0,0)",
                  }}
                >
                  <Grid container sx={{ mb: 1 }}>
                    <Grid item xs={8} md={8} lg={8}>
                      <Box display="flex">
                        <Typography variant="body">{item.title}</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4} md={4} lg={4}>
                      <Box display="flex" justifyContent="flex-end">
                        <Typography variant="caption">
                          {format(new Date(item.created_date), "dd-MM-yyyy")}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                  <Typography variant="body2">{item.description}</Typography>
                  {item.link_konfirmasi && item.status == 0 && (
                    <Button
                      size="small"
                      color="primary"
                      variant="contained"
                      sx={{ marginTop: "10px", marginRight: "10px" }}
                      onClick={() => {
                        excLink(item.link_konfirmasi);
                      }}
                    >
                      IYA
                    </Button>
                  )}
                  {item.link_cancel && item.status == 0 && (
                    <Button
                      size="small"
                      variant="outlined"
                      sx={{ marginTop: "10px", marginRight: "10px" }}
                      onClick={() => {
                        excLink(item.link_cancel);
                      }}
                    >
                      TIDAK
                    </Button>
                  )}
                </CardContent>
              </Card>
            );
          })}
          <Button
            variant="text"
            size="small"
            onClick={() => {
              setPage(page + 10);
            }}
          >
            Tampilkan lebih banyak
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Notification;
