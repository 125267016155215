import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAngleRight,
  faDollarSign,
  faPlus,
  faTrashCan,
  faTruckArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CardContent,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Popover,
  TextField,
} from "@mui/material";
import api from "../../pages/ApiDashboard";
import { toast } from "react-toastify";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Navigate, useNavigate } from "react-router-dom";

export function FullButton({goto, text ,chip , chipCollor}){
    const navigate = useNavigate();
    return (
        <Card
        sx={{ padding: 1,marginBottom:"10px" }}
        onClick={() => {
          navigate(goto);
        }}
      >
        <Grid container>
          <Grid item xs={11} md={11} lg={11}>
            <Box display="flex" sx={{paddingLeft:"5px"}}>
              <Typography variant="body">{text}</Typography>
            </Box>
          </Grid>
          <Grid item xs={1} md={1} lg={1}>
            <Box display="flex" justifyContent="flex-end">
              <Typography variant="body" sx={{color:"red"}}>
                <FontAwesomeIcon icon={faAngleRight} ></FontAwesomeIcon>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Card>
    )
}
