import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          margin: "auto",
          transition: "0.3s",
          borderRadius: "20px",
          boxShadow: "0px 0px 10px 1px rgba(0,0,0,0.1)",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h6: {
          fontWeight: "bold",
        },
        body: {
          fontWeight: "bold",
        },
        body2: {
          fontWeight: "bold",
          color:"grey"
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          marginTop: "5px",
          marginBottom: "5px",
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          right: -25,
          top: 0,
          backgroundColor: "orange",
          padding: "0 5px",
          color: "white",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "50px",
          textTransform: "none",
        },
        contained: {
          backgroundColor: "#ff4d4d",
          "&:hover": {
            backgroundColor: "#ff8080",
          },
          color: "#fff",
        },
        outlined: {
          backgroundColor: "white",
          color: "#ff8080",
          border: "2px solid #ff8080",
          "&:hover": {
            border: "2px solid #ff8080",
          },
        },
        text: {
          color: "#ff4d4d",
          "&:hover": {
            backgroundColor: "transparent",
          },
        },
      },
    },

    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-root": {
            "&:not(:placeholder-shown)": {},

            "& fieldset": {
              borderRadius: "19px",
              color: "red",
              "& legend ": {
                fontSize: "8px",
                marginLeft: "8px",
              },
            },
            "&:hover fieldset": {
              borderColor: "rgba(0, 0, 0, 0.23)",
            },
            "&.Mui-focused": {
              "& fieldset": {
                border: "1px solid #ff4d4d",
                color: "#ff4d4d",
              },
            },
          },
          "& .MuiInputLabel-root": {
            fontSize: "12px",
            top: "3px",
            left: "5px",
            color: "rgba(0,0,0,0.5)",
            "&.Mui-focused": {
              color: "red",
              top: "2px",
              left: "6px",
            },
            "&.MuiInputLabel-shrink": {
              left: "6px",
              top: "2px",
            },
          },
        },
      },
    },
  },
  palette: {
    error: {
      main: "#ef5350",
      light: "#ef5350",
      dark: "#ef5351",
    },
    success: {
      main: "#ff4d4d",
    },
    primary: {
      main: "#00a334",
    },
  },
});
export default theme;
