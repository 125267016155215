import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDollarSign,
  faPlus,
  faTrashCan,
  faTruckArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  MenuItem,
  Modal,
  Popover,
  TextField,
} from "@mui/material";
import api from "../../pages/ApiDashboard";
import { toast } from "react-toastify";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export function FilterDate({
  open,
  onClose,
  anchorEl,
  onApplyFilter,
  start,
  end,
  changeStart,
  changeEnd,
}) {
  if (!open) return null;

  const handleFilter = () => {
    onClose();
    onApplyFilter();
  };
  const handleStart = (e) => {
    changeStart(e);
  };
  const handleEnd = (e) => {
    changeEnd(e);
  };
  const removeFilter = () => {
    changeStart(null);
    changeEnd(null);
  };

  return (
    <Popover
      id="filter-menu"
      open={open}
      anchorEl={anchorEl}
      onClose={() => {
        onClose();
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <div style={{ padding: "20px", width: "250px" }}>
        <Typography variant="body" sx={{ fontSize: "14px" }}>
          Filter Tanggal
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker"]}>
            <DatePicker
              label="Tanggal Mulai"
              slotProps={{ textField: { size: "small" } }}
              value={start}
              onChange={handleStart}
              format="DD-MM-YYYY"
              sx={{ mt: 2 }}
            />
          </DemoContainer>
          <DemoContainer components={["DatePicker"]}>
            <DatePicker
              label="Tanggal Selesai"
              slotProps={{ textField: { size: "small" } }}
              value={end}
              onChange={handleEnd}
              format="DD-MM-YYYY"
              sx={{ mt: 2 }}
            />
          </DemoContainer>
        </LocalizationProvider>
        <Button
          fullwidth
          variant="outlined"
          sx={{ mt: 2 }}
          onClick={() => {
            removeFilter();
          }}
        >
          Hilangkan
        </Button>
        <Button
          fullwidth
          variant="contained"
          sx={{ mt: 2, ml: 1 }}
          onClick={() => {
            handleFilter();
          }}
        >
          Terapkan
        </Button>
      </div>
    </Popover>
  );
}
export function FilterTransaksi({
  open,
  onClose,
  anchorEl,
  onApplyFilter,
  ddalam,
  dstart,
  dend,
}) {
  const [data, setData] = useState({
    dalam: "n",
    start: dstart,
    end: dend,
  });
  if (!open) return null;

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <div style={{ padding: "20px", width: "250px" }}>
        <Typography variant="body" sx={{ fontSize: "14px" }}>
          Filter Data
        </Typography>
        <TextField
          select
          label="Dalam"
          defaultValue="n"
          fullWidth
          size="small"
          value={data.dalam}
          onChange={(e) => {
            setData({ ...data, dalam: e.target.value });
          }}
          sx={{ mt: 2 }}
        >
          <MenuItem key="Harian" value="n">
            Harian
          </MenuItem>
          <MenuItem key="Aktif" value="mingguan">
            Minnguan
          </MenuItem>
          <MenuItem key="Tersembunyi" value="bulanan">
            Bulanan
          </MenuItem>
          <MenuItem key="Tahunan" value="tahunan">
            Tahunan
          </MenuItem>
        </TextField>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker"]}>
            <DatePicker
              label="Tanggal Mulai"
              slotProps={{ textField: { size: "small" } }}
              value={data.start}
              onChange={(e) => {
                setData({ ...data, start: e });
              }}
              format="DD-MM-YYYY"
              sx={{ mt: 2 }}
            />
          </DemoContainer>
          <DemoContainer components={["DatePicker"]}>
            <DatePicker
              label="Tanggal Selesai"
              slotProps={{ textField: { size: "small" } }}
              value={data.end}
              onChange={(e) => {
                setData({ ...data, end: e });
              }}
              format="DD-MM-YYYY"
              sx={{ mt: 2 }}
            />
          </DemoContainer>
        </LocalizationProvider>

        <Button
          fullwidth
          variant="outlined"
          sx={{ mt: 2 }}
          onClick={() => {
            setData({
              dalam: "",
              start: null,
              end: null,
            });
            onApplyFilter(data, "N");
          }}
        >
          Hilangkan
        </Button>
        <Button
          fullwidth
          variant="contained"
          sx={{ mt: 2, ml: 1 }}
          onClick={() => {
            onApplyFilter(data, "Y");
          }}
        >
          Terapkan
        </Button>
      </div>
    </Popover>
  );
}

export function FilterMarketPlace({ open, onClose, anchorEl, onApplyFilter }) {
  useEffect(() => {}, []);
  const [data, setData] = useState({
    kategori: "n",
    toko: "n",
  });
  const [toko, setToko] = useState();
  const token = localStorage.getItem("Mitra-Token");
  useEffect(() => {
    const response = api
      .get("toko", {
        headers: { "Mitra-Token": token },
      })
      .then((response) => {
        setToko(response.data.data);
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else if (error.message) {
          toast.error(error.message);
        } else {
          toast.error("An error occurred. Please try again later.");
        }
      });
  }, []);
  useEffect(() => {
    console.log(toko);
  }, [toko]);
  if (!open) return null;

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <div style={{ padding: "20px", width: "250px" }}>
        <Typography variant="body" sx={{ fontSize: "14px" }}>
          Filter Data
        </Typography>
        <TextField
          select
          label="Kategori"
          defaultValue="n"
          fullWidth
          size="small"
          value={data.kategori}
          onChange={(e) => {
            setData({ ...data, kategori: e.target.value });
          }}
          sx={{ mt: 2 }}
        >
          <MenuItem key="Semua" value="n">
            Semua
          </MenuItem>
          <MenuItem key="Aktif" value="tv">
            Televisi
          </MenuItem>
          <MenuItem key="Tersembunyi" value="hp">
            Handphone
          </MenuItem>
          <MenuItem key="Laptop" value="laptop">
            Laptop
          </MenuItem>
        </TextField>

        <TextField
          select
          label="Toko"
          defaultValue="n"
          fullWidth
          size="small"
          value={data.toko}
          onChange={(e) => {
            setData({ ...data, toko: e.target.value });
          }}
          sx={{ mt: 2 }}
        >
          <MenuItem key="Semua" value="n">
            Semua
          </MenuItem>
          {toko.map((item) => (
            <MenuItem key={item.name} value={item.name}>
              {item.name}
            </MenuItem>
          ))}

          <MenuItem key="Tersembunyi" value="laptop">
            Handphone
          </MenuItem>
          <MenuItem key="Laptop" value="laptop">
            Laptop
          </MenuItem>
        </TextField>
        <Button
          fullwidth
          variant="outlined"
          sx={{ mt: 2 }}
          onClick={() => {
            setData({
              kateogir: null,
              toko: null,
            });
            onApplyFilter(data, "NM");
          }}
        >
          Hilangkan
        </Button>
        <Button
          fullwidth
          variant="contained"
          sx={{ mt: 2, ml: 1 }}
          onClick={() => {
            onApplyFilter(data, "Y");
          }}
        >
          Terapkan
        </Button>
      </div>
    </Popover>
  );
}

export function SortMarketPlace({ open, onClose, anchorEl, onApplyFilter }) {
  const [data, setData] = useState({
    sort: "n",
  });
  if (!open) return null;

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <div style={{ padding: "20px", width: "250px" }}>
        <Typography variant="body" sx={{ fontSize: "14px" }}>
          Urutkan
        </Typography>
        <TextField
          select
          label="Berdasarkan"
          defaultValue="n"
          fullWidth
          size="small"
          value={data.sort}
          onChange={(e) => {
            setData({ ...data, sort: e.target.value });
          }}
          sx={{ mt: 2 }}
        >
          <MenuItem key="Views" value="n">
            Dilihat
          </MenuItem>
          <MenuItem key="harga" value="harga">
            Harga termurah - termahal
          </MenuItem>
          <MenuItem key="hargaDesc" value="hargaDesc">
            Harga termahal - termurah
          </MenuItem>
          <MenuItem key="nama" value="nama">
            Nama
          </MenuItem>
        </TextField>

        <Button
          fullwidth
          variant="outlined"
          sx={{ mt: 2 }}
          onClick={() => {
            setData({
              sort: null,
            });
            onApplyFilter(data, "NS");
          }}
        >
          Hilangkan
        </Button>
        <Button
          fullwidth
          variant="contained"
          sx={{ mt: 2, ml: 1 }}
          onClick={() => {
            onApplyFilter(data, "Y");
          }}
        >
          Terapkan
        </Button>
      </div>
    </Popover>
  );
}
