import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQuestion,
  faTrashCan,
  faTruckArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { Grid, Modal, TextField } from "@mui/material";

function ModalSubsidi({ isOpen, onClose, onChange, cour, other }) {
  if (!isOpen) return null;
  const [cours, setCour] = useState(cour);
  const [others, setOther] = useState(other);

  const handlePerpanjang = () => {
    onChange(cours, others);
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 300,
          p: 2,
          textAlign: "center",
        }}
      >
        <Typography id="modal-modal-title" variant="h6" component="h3">
          KONFIRMASI
        </Typography>
        <FontAwesomeIcon
          icon={faQuestion}
          style={{ fontSize: "50px", marginTop: "20px" }}
        />

        <Grid container spacing={1} sx={{ mt: 1 }}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={others}
              onChange={(e) => {
                setOther(e.target.value);
              }}
              sx={{ mb: 2 }}
              label="Subsidi Seeker / Seller"
            ></TextField>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={cours}
              onChange={(e) => {
                setCour(e.target.value);
              }}
              sx={{ mb: 2 }}
              label="Subsidi Kurir"
            ></TextField>
          </Grid>
          <Grid item xs={6}>
            <Button variant="outlined" onClick={onClose} fullWidth>
              BATAL
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" onClick={handlePerpanjang} fullWidth>
              BERIKAN
            </Button>
          </Grid>
        </Grid>
      </Card>
    </Modal>
  );
}

export default ModalSubsidi;
