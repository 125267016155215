import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import api from "../ApiDashboard";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Container,
  Box,
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  Divider,
  IconButton,
  Stack,
  Popper,
  Grow,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Menu,
  ListItemIcon,
  Modal,
  Chip,
  Popover,
  TextField,
  Paper,
  InputBase,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Pagination, Table, Spin } from "antd";
import {
  faAdd,
  faBan,
  faCalendarAlt,
  faCaretDown,
  faCheckCircle,
  faDollar,
  faEllipsis,
  faFilter,
  faMagnifyingGlass,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";
import ReportsExcel from "../../components/reports/Reports";
import ModalFilterDate from "../../components/modal/modalFilterDate";

const BarangTransfer = () => {
  //---------------var filter--------------
  const [kategori, setKategori] = useState("n");
  const [statusPenggunaFilter, setStatusPenggunaFilter] = useState("n");
  const [statusTransferFilter, setStatusTransferFilter] = useState("n");
  const [filterMenu, setFilterMenu] = useState(null);
  const filter = Boolean(filterMenu);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [urlPrint, setUrlPrint] = useState("");
  const [filterDate, setFilterDate] = useState(null);
  const date = Boolean(filterDate);
  //------------var search--------------
  const [searchMenuStat, setSearchMenuStat] = useState(null);
  const searchMenu = Boolean(searchMenuStat);
  const [search, setSearch] = useState("");
  const [searchText, setSearchText] = useState("ID Barang");
  const [searchVal, setSearchVal] = useState("id_barang");

  const token = localStorage.getItem("Mitra-Token");
  const navigate = useNavigate();

  const fetchData = async (page, rowsPerPage, clearSearch = "N") => {
    try {
      let url = `v1/dashboard/transfer_barang?page=${page}&per_page=${rowsPerPage}`;
      if (kategori !== "n") {
        url = url + "&kategori=" + kategori;
      }
      if (statusPenggunaFilter !== "n") {
        url = url + "&pengguna=" + statusPenggunaFilter;
      }
      if (statusTransferFilter !== "n") {
        url = url + "&status=" + statusTransferFilter;
      }
      if (search !== "" && clearSearch === "N") {
        url = url + "&" + searchVal + "=" + search;
      }
      if (startDate) {
        const formattedDate = dayjs(startDate).format("YYYY-MM-DD");
        url = url + "&start=" + formattedDate;
      }
      if (endDate) {
        const formattedDate = dayjs(endDate).format("YYYY-MM-DD");
        url = url + "&end=" + formattedDate;
      }
      setUrlPrint(url + "&print=Y");

      const response = await api.get(url, {
        headers: { "Mitra-Token": token },
      });
      return response.data.data;
    } catch (error) {
      throw error;
    }
  };
  const goTo = (path) => () => {
    navigate(path);
  };
  const changeStart = (start) => {
    setStartDate(start);
  };
  const changeEnd = (end) => {
    setEndDate(end);
  };
  const removeFilter = () => {
    setKategori("n");
    setStatusPenggunaFilter("n");
    setStatusTransferFilter("n");
  };
  const clearSearch = async () => {
    const responseData = await fetchData(page, rowsPerPage, "Y");
    setData(responseData.data);
    setRowsPerPage(responseData.last_page);
    setTotal(responseData.total);
    setPageSize(responseData.per_page);
  };
  const addFilterDate = async () => {
    setFilterDate(null);
    const responseData = await fetchData(page, rowsPerPage);
    setData(responseData.data);
    setRowsPerPage(responseData.last_page);
    setTotal(responseData.total);
    setPageSize(responseData.per_page);
  };
  const addFilter = async () => {
    setFilterMenu(null);
    const responseData = await fetchData(page, rowsPerPage);
    setData(responseData.data);
    setRowsPerPage(responseData.last_page);
    setTotal(responseData.total);
    setPageSize(responseData.per_page);
  };

  const handleSearch = async () => {
    const responseData = await fetchData(page, rowsPerPage);
    setData(responseData.data);
    setRowsPerPage(responseData.last_page);
    setTotal(responseData.total);
    setPageSize(responseData.per_page);
  };
  const searchBy = [
    { val: "keterangan", text: "Keterangan" },
    { val: "id_barang", text: "ID Barang" },
    { val: "name", text: "Nama Barang" },
    { val: "no_seri", text: "Nomor Seri" },
  ];
  const handleCloseSearch = () => {
    setSearchMenuStat(null);
  };
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [id, setId] = useState("");
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(0);
  const [pageSize, setPageSize] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [statusTransfer, setStatusTransfer] = useState("");
  const [modalTfOpen, setModalTfOpen] = useState(false);
  const [statusPengguna, setStatusPengguna] = useState("");

  useEffect(() => {
    const fetchDataAndStopLoading = async () => {
      try {
        const responseData = await fetchData(page, rowsPerPage);
        setData(responseData.data);
        setRowsPerPage(responseData.last_page);
        setTotal(responseData.total);
        setPageSize(responseData.per_page);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };

    fetchDataAndStopLoading();
  }, [page, rowsPerPage]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (id, status_transfer, status) => (event) => {
    setStatusTransfer(status_transfer);
    setStatusPengguna(status);
    setId(id);
    setAnchorEl(event.currentTarget);
  };
  const handleTransfer = async (status) => {
    setAnchorEl(null);

    try {
      const response = await api.put(
        `v1/dashboard/verified_transfer_barang?id=${id}&status=${status}`,
        null,
        {
          headers: { "Mitra-Token": token },
        }
      );

      const resData = response.data;
      if (resData.status == "success") {
        toast.success(resData.message);
        const responseData = await fetchData(page, rowsPerPage);
        setData(responseData.data);
        setRowsPerPage(responseData.last_page);
        setTotal(responseData.total);
        setPageSize(responseData.per_page);
      } else {
        toast.warning(resData.message);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("An error occurred. Please try again later.");
      }
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const columns = [
    {
      title: "Info Barang",
      dataIndex: "id_barang",
      key: "id_barang",
      width: "25%",
      render: (
        _,
        {
          id_barang,
          no_seri,
          name,
          category,
          sell_price,
          status_transfer,
          status,
          id,
        }
      ) => (
        <>
          <Stack
            direction="row"
            spacing={1}
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick(id, status_transfer, status)}
          >
            <Typography
              variant="body2"
              style={{
                fontWeight: "bold",
              }}
            >
              #{id_barang}
            </Typography>
            <Typography variant="body2">
              {category} - {name}
            </Typography>
          </Stack>
          <Stack>
            <Typography variant="caption" sx={{ color: "rgba(0,0,0,0.5)" }}>
              {no_seri}
            </Typography>
            <Typography variant="caption" sx={{ color: "rgba(0,0,0,0.8)" }}>
              Rp. {sell_price}
            </Typography>
          </Stack>
        </>
      ),
    },
    {
      title: "Keterangan",
      dataIndex: "description",
      width: "15%",
      key: "description",
    },
    {
      title: "Tanggal dibuat",
      dataIndex: "created_date",
      ellipsis: true,
      width: "10%",
      key: "created_date",
    },
    {
      title: "Status Pengguna",
      dataIndex: "status_transfer",
      ellipsis: true,
      width: "13%",
      key: "status_transfer",
    },
    {
      title: "Status Transfer",
      dataIndex: "status",
      ellipsis: true,
      key: "status",
      width: "13%",

      render: (_, { status }) => (
        <div>
          {status === 0 && (
            <Chip
              style={{ margin: "0 auto" }}
              color="warning"
              label="Proses"
              size="small"
            />
          )}
          {status === 1 && (
            <Chip
              style={{ margin: "0 auto" }}
              color="primary"
              label="Lunas"
              size="small"
            />
          )}
          {status === 2 && (
            <Chip
              style={{ margin: "0 auto" }}
              color="error"
              label="Ditolak"
              size="small"
            />
          )}
          {status === 3 && (
            <Chip
              style={{ margin: "0 auto" }}
              size="small"
              color="warning"
              label="Piutang"
            />
          )}
        </div>
      ),
    },
  ];
  // const tokenString = localStorage.getItem("Mitra-Token");
  return (
    <Container
      style={{
        justifyContent: "right",
      }}
    >
      <Popover
        id="filter-menu"
        open={filter}
        anchorEl={filterMenu}
        onClose={(e) => {
          setFilterMenu(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div style={{ padding: "20px", width: "250px" }}>
          <Typography variant="body" sx={{ fontSize: "14px" }}>
            Filter
          </Typography>
          <TextField
            select
            label="Kategori"
            defaultValue="n"
            fullWidth
            size="small"
            value={kategori}
            onChange={(e) => {
              setKategori(e.target.value);
            }}
            sx={{ mt: 2 }}
          >
            <MenuItem key="Tidak ada" value="n">
              Tidak ada
            </MenuItem>
            <MenuItem key="HP" value="HP">
              HP
            </MenuItem>
            <MenuItem key="TV" value="TV">
              TV
            </MenuItem>
            <MenuItem key="LAPTOP" value="LAPTOP">
              LAPTOP
            </MenuItem>
          </TextField>
          <TextField
            select
            label="Status pengguna"
            defaultValue="n"
            fullWidth
            size="small"
            value={statusPenggunaFilter}
            onChange={(e) => {
              setStatusPenggunaFilter(e.target.value);
            }}
            sx={{ mt: 2 }}
          >
            <MenuItem key="Tidak ada" value="n">
              Tidak ada
            </MenuItem>
            <MenuItem key="Pengirim" value="from">
              Pengirim
            </MenuItem>
            <MenuItem key="Penerima" value="to">
              Penerima
            </MenuItem>
          </TextField>
          <TextField
            select
            label="Status Transfer"
            defaultValue="n"
            fullWidth
            size="small"
            value={statusTransferFilter}
            onChange={(e) => {
              setStatusTransferFilter(e.target.value);
            }}
            sx={{ mt: 2 }}
          >
            <MenuItem key="Tidak ada" value="n">
              Tidak ada
            </MenuItem>
            <MenuItem key="Proses" value="0">
              Proses
            </MenuItem>
            <MenuItem key="Lunas" value="1">
              Lunas
            </MenuItem>
            <MenuItem key="Ditolak" value="2">
              Ditolak
            </MenuItem>
            <MenuItem key="Piutang" value="3">
              Piutang
            </MenuItem>
          </TextField>
          <Button
            fullwidth
            variant="outlined"
            sx={{ mt: 2 }}
            onClick={() => {
              removeFilter();
            }}
          >
            Hilangkan
          </Button>
          <Button
            fullwidth
            variant="contained"
            sx={{ mt: 2, ml: 1 }}
            onClick={() => {
              addFilter();
            }}
          >
            Terapkan
          </Button>
        </div>
      </Popover>
      <ModalFilterDate
        open={date}
        anchorEl={filterDate}
        onClose={(e) => {
          setFilterDate(null);
        }}
        onApplyFilter={addFilterDate}
        start={startDate}
        end={endDate}
        changeStart={changeStart}
        changeEnd={changeEnd}
      ></ModalFilterDate>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {statusTransfer === "Penerima" && statusPengguna === 0 && (
          <MenuItem
            onClick={(e) => {
              handleTransfer(3);
            }}
            style={{ color: "green" }}
          >
            <ListItemIcon>
              <FontAwesomeIcon
                icon={faCheckCircle}
                style={{ color: "green" }}
              ></FontAwesomeIcon>
            </ListItemIcon>
            <Typography variant="inherit">Terima</Typography>
          </MenuItem>
        )}
        {statusPengguna === 0 && (
          <MenuItem
            onClick={(e) => {
              handleTransfer(2);
            }}
            style={{ color: "red" }}
          >
            <ListItemIcon>
              <FontAwesomeIcon
                icon={faTimesCircle}
                style={{ color: "red" }}
              ></FontAwesomeIcon>
            </ListItemIcon>
            <Typography variant="inherit">Batal</Typography>
          </MenuItem>
        )}

        {statusPengguna === 3 && statusTransfer === "Pengirim" && (
          <MenuItem
            onClick={(e) => {
              handleTransfer(1);
            }}
            style={{ color: "orange" }}
          >
            <ListItemIcon>
              <FontAwesomeIcon
                icon={faDollar}
                style={{ color: "orange" }}
              ></FontAwesomeIcon>
            </ListItemIcon>
            <Typography variant="inherit">Lunas</Typography>
          </MenuItem>
        )}
        {statusPengguna !== 0 && statusPengguna !== 3 && (
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <FontAwesomeIcon icon={faBan}></FontAwesomeIcon>
            </ListItemIcon>
            <Typography variant="inherit">Tidak ada aksi</Typography>
          </MenuItem>
        )}
      </Menu>
      <Grid container spacing={1}>
        <Grid item xs={8} md={8} lg={8}>
          <Typography gutterBottom variant="h6" component="div">
            Barang Transfer
          </Typography>
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <Box display="flex" justifyContent="flex-end">
            <IconButton
              sx={{ right: 0 }}
              variant="text"
              color="error"
              size="small"
              fullwidth
              onClick={(e) => {
                setFilterMenu(e.currentTarget);
              }}
            >
              <FontAwesomeIcon icon={faFilter}></FontAwesomeIcon>
            </IconButton>
            <IconButton
              sx={{ right: 0 }}
              variant="text"
              color="error"
              size="small"
              fullwidth
              onClick={(e) => {
                setFilterDate(e.currentTarget);
              }}
            >
              <FontAwesomeIcon icon={faCalendarAlt}></FontAwesomeIcon>
            </IconButton>
            <ReportsExcel
              laporan={
                (startDate &&
                  endDate &&
                  "Data Transfer " + startDate + " - " + endDate) ||
                "Data Transfer (All) - " + new Date().toLocaleDateString()
              }
              url={urlPrint}
            ></ReportsExcel>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={12} md={12} lg={12}>
          <Card>
            <CardContent>
              <Grid container sx={{ mb: 1 }}>
                <Grid item xs={12} md={8} lg={8}>
                  <Typography gutterBottom variant="body" component="div">
                    Jumlah {total}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <Paper
                    style={{
                      borderRadius: "20px",
                      padding: "2px 2px 2px 2px",
                      shadow: "none",
                      border: "none",
                    }}
                  >
                    <Menu
                      dense
                      id="search-menu"
                      open={searchMenu}
                      anchorEl={searchMenuStat}
                      onClose={handleCloseSearch}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      {searchBy.map((item) => (
                        <MenuItem
                          onClick={(e) => {
                            handleCloseSearch();
                            setSearchVal(item.val);
                            setSearchText(item.text);
                          }}
                        >
                          <Typography variant="caption">{item.text}</Typography>
                        </MenuItem>
                      ))}
                    </Menu>
                    <Stack direction="row" spacing={0.5}>
                      <IconButton
                        size="small"
                        onClick={() => {
                          handleSearch();
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faMagnifyingGlass}
                        ></FontAwesomeIcon>
                      </IconButton>
                      <InputBase
                        fullWidth
                        size="small"
                        style={{ marginTop: "2px", fontSize: "14px" }}
                        placeholder={"Cari berdasarkan " + searchText}
                        value={search}
                        onChange={(e) => {
                          setSearch(e.target.value);
                        }}
                      ></InputBase>
                      {search !== "" && (
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            setSearch("");
                            clearSearch();
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faTimes}
                            style={{ fontSize: "15px" }}
                          ></FontAwesomeIcon>
                        </IconButton>
                      )}
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          setSearchMenuStat(e.currentTarget);
                        }}
                      >
                        <FontAwesomeIcon icon={faCaretDown}></FontAwesomeIcon>
                      </IconButton>
                    </Stack>
                  </Paper>
                </Grid>
              </Grid>

              <Table
                scroll={{
                  x: 600,
                }}
                columns={columns}
                dataSource={data}
                pagination={false}
                loading={isLoading}
                size="small"
              />
              <Pagination
                style={{ marginTop: "10px" }}
                current={page}
                pageSize={pageSize}
                total={total}
                onChange={handlePageChange}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default BarangTransfer;
