import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../css/Login.css";
import "../css/Form.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import api from "./Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UAParser from "ua-parser-js";
import {
  Container,
  Box,
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  TextField,
  InputAdornment,
  Divider,
} from "@mui/material";

const Login = () => {
  const parser = new UAParser();
  const userAgentData = parser.getResult();
  const browser = userAgentData.browser.name;
  const device = userAgentData.device.model;
  const os = userAgentData.os.name + "/" + userAgentData.os.version;
  const [phone, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [otpToken, setOtpToken] = useState("");
  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  const navigate = useNavigate();
  const goTo = (path) => () => {
    navigate(path);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("phone", phone);
    formData.append("password", password);
    formData.append("device", device);
    formData.append("os", os);
    formData.append("browser", browser);
    api
      .post("login", formData)
      .then((response) => {
        const { status, message, data } = response.data;

        if (status === "success") {
          if (data["Token"]) {
            localStorage.setItem("Mitra-Token", data["Token"]);
            localStorage.setItem("Refresh-Token", data["Refresh-Token"]);
            toast.success(message);
            navigate("/");
          } else {
            toast.success(message);
            localStorage.setItem("Otp-Token", data["Otp-Token"]);
            const otpToken = data["Otp-Token"];
            setOtpToken(otpToken);
            navigate("/auth/otp", { state: { otpToken } });
          }
        }
      })
      .catch((error) => {
        console.log(error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else if (error.message) {
          toast.error(error.message);
        } else {
          toast.error("An error occurred. Please try again later.");
        }
      });
  };
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Container>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={3}
      >
        <Grid item xs={12} sm={6} l={6}>
          <Card sx={{ p: 4, borderRadius: "50px" }}>
            <label className="header-text">Selamat datang</label>
            <label className="under-header-text">
              Silahkan masuk terlebih dahulu
            </label>

            <form onSubmit={handleSubmit}>
              <TextField
                label="Nomor Hp"
                variant="outlined"
                size="small"
                value={phone}
                onChange={handleUsernameChange}
                fullWidth
                required
                sx={{ mb: 3 }}
              />
              <TextField
                label="Password"
                variant="outlined"
                size="small"
                type={showPassword ? "text" : "password"}
                onChange={handlePasswordChange}
                value={password}
                sx={{ mb: 3 }}
                fullWidth
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <FontAwesomeIcon
                        onClick={togglePasswordVisibility}
                        icon={showPassword ? faEyeSlash : faEye}
                      />
                    </InputAdornment>
                  ),
                }}
              />
              <Button variant="text">Lupa Password</Button>

              <Button
                variant="contained"
                onClick={handleSubmit}
                sx={{ width: "100%" }}
              >
                Masuk
              </Button>
            </form>
            <Typography variant="body2">
              Belum punya akun?
              <Button variant="text" onClick={goTo("/auth/register")}>
                Daftar di sini
              </Button>
            </Typography>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Login;
