import { BrowserRouter } from "react-router-dom";
import Router from "./routes";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./utils/theme";
import ScrollToTop from "./components/scroll-to-top";
import { ToastContainer } from "react-toastify";

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <ScrollToTop />
        <Router />
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar
          limit={1}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          theme="colored"
        />
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
