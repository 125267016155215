import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import api from "../ApiDashboard";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo_3 from "../../img/logo-3.png";
import Paper from "@mui/material/Paper";
import {
  Container,
  Box,
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  Divider,
  IconButton,
  Stack,
  TextField,
  InputAdornment,
  MenuItem,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  Chip,
} from "@mui/material";
import Slider from "react-slick";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TableComponent from "../../components/table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa";
import { MutatingDots } from "react-loader-spinner";
import { Pagination, Table, Spin } from "antd";
import { styled } from "@mui/material/styles";
import {
  faAdd,
  faCamera,
  faImage,
  faPlus,
  faTimes,
  faTimesRectangle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import RadioGroupContext from "@mui/material/RadioGroup/RadioGroupContext";
import { useRef } from "react";
import { DateField, LocalizationProvider } from "@mui/x-date-pickers";
import { NumericFormat } from "react-number-format";

const BarangDetail = () => {
  const token = localStorage.getItem("Mitra-Token");
  const navigate = useNavigate();
  const [nama, setNama] = useState("");
  const [kategori, setKategori] = useState("");
  const [seri, setSeri] = useState("");
  const [label, setLabel] = useState("");
  const [buy_price, setBuy_price] = useState(0);
  const [sell_price, setSell_price] = useState("");
  const [deskripsi, setDeskripsi] = useState("");
  const [gambar, setGambar] = useState([]);
  const [gambarKtp, setGambarKtp] = useState(null);
  const [imageUrl, setImageUrl] = useState([]);
  const [imageKtpUrl, setImageKtpUrl] = useState(null);
  const [seeker, setSeeker] = useState("");
  const [status, setStatus] = useState(0);
  const fileInputRef = useRef(null);

  const [kurir, setKurir] = useState("");
  const [kurirPenjualan, setKurirPenjualan] = useState("");
  const [data_account, setData] = useState([]);
  const [data_account_loaded, setDataLoaded] = useState(false);
  const [detail, setDetail] = useState(false);
  const params = useParams();
  //---------------------param gadai---------------
  const [gadai, setGadai] = useState("");
  // const [phone, setPhone] = useState("");
  // const [nama_penggadai, setNamaPenggadai] = useState("");
  // const [alamat, setAlamat] = useState("");
  // const [biaya_titip, setBiayaTitip] = useState("");
  // const [tanggal_mulai, setTanggalMulai] = useState("");
  // const [lama_hari, setLamaHari] = useState("");
  // const [total_pinjaman, setTotalPinjaman] = useState("");
  // const [mht, setMht] = useState("");
  // const [keterangan, setKeterangan] = useState("");
  // const [waktu_lelang, setWaktuLelang] = useState("");

  const fetchData = async () => {
    try {
      const response = await api.get(
        "v1/dashboard/barang/detail?id=" + params.id,
        {
          headers: {
            "Mitra-Token": token,
          },
        }
      );
      return response.data.data;
    } catch (error) {
      throw error;
    }
  };
  if (params.id) {
    useEffect(() => {
      setDetail(true);
      const fetchDataAndStopLoading = async () => {
        try {
          const data = await fetchData();
          setNama(data.name);
          setKategori(data.category);
          setSeri(data.no_seri);
          setLabel(data.label);
          setBuy_price(data.buy_price);
          setSell_price(data.sell_price);
          setDeskripsi(data.description);
          setImageUrl(data.image.split(";"));
          setSeeker(data.seeker ? data.seeker.member_id : null);
          setKurir(
            data.kurir_pembelian ? data.kurir_pembelian.member_id : null
          );
          setKurirPenjualan(
            data.kurir_penjualan ? data.kurir_penjualan.member_id : null
          );
          setStatus(data.status);
        } catch (error) {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            toast.error(error.response.data.message);
          } else if (error.message) {
            toast.error(error.message);
          } else {
            toast.error("An error occurred. Please try again later.");
          }
        }
      };
      fetchDataAndStopLoading();
    }, []);
  } else {
  }

  const hapusGambar = (preview) => async () => {
    try {
      const response = await api.delete(
        "v1/dashboard/barang/gambar?id=" + params.id + "&image=" + preview,
        {
          headers: {
            "Mitra-Token": token,
          },
        }
      );
      const resData = response.data;
      if (resData.status == "success") {
        toast.success(resData.message);
        const data = await fetchData();
        setImageUrl(data.image.split(";"));
        toast.success(response.data.message);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("An error occurred. Please try again later.");
      }
    }
  };
  const goTo = (path) => () => {
    navigate(path);
  };
  useEffect(() => {
    const staff_account = api
      .get("v1/dashboard/staff_account", {
        headers: {
          "Mitra-Token": token,
        },
      })
      .then((response) => {
        setDataLoaded(true);
        const { status, message, data } = response.data;
        setData(data);
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else if (error.message) {
          toast.error(error.message);
        } else {
          toast.error("An error occurred. Please try again later.");
        }
      });
  }, []);
  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("name", nama);
    formData.append("category", kategori);
    formData.append("label", label);
    formData.append("buy_price", buy_price);
    formData.append("sell_price", sell_price);
    formData.append("description", deskripsi);
    for (let i = 0; i < gambar.length; i++) {
      formData.append("image[]", gambar[i]);
    }
    if (seeker) {
      formData.append("seeker", seeker);
    }
    if (kurir) {
      formData.append("courier", kurir);
    }
    if (kurirPenjualan) {
      formData.append("sell_courier", kurirPenjualan);
    }
    formData.append("no_seri", seri);
    formData.append("gadai", gadai);
    if (detail) {
      formData.append("id", params.id);
      api
        .post("v1/dashboard/barang/update", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            "Mitra-Token": token,
          },
        })
        .then((response) => {
          const { status, message, data } = response.data;
          if (status === "success") {
            toast.success(message);
            navigate("/dashboard/barang");
          }
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            toast.error(error.response.data.message);
          } else if (error.message) {
            toast.error(error.message);
          } else {
            toast.error("An error occurred. Please try again later.");
          }
        });
    } else {
      api
        .post("v1/dashboard/barang", formData, {
          headers: {
            "Mitra-Token": token,
          },
        })
        .then((response) => {
          const { status, message, data } = response.data;
          if (status === "success") {
            toast.success(response.data.message);
            navigate("/dashboard/barang");
          }
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            toast.error(error.response.data.message);
          } else if (error.message) {
            toast.error(error.message);
          } else {
            toast.error("An error occurred. Please try again later.");
          }
        });
    }
  };
  const handleImageKtpChange = async (e) => {
    const files = e.target.files;
    setGambarKtp(files[0]);
    for (let i = 0; i < files.length; i++) {
      const reader = new FileReader();
      reader.readAsDataURL(files[i]);
      reader.onload = () => {
        setImageKtpUrl(reader.result);
      };
    }
  };

  const handleImageChange = async (e) => {
    const files = e.target.files;
    setGambar((prev) => [...prev, ...files]);
    const previews = [];
    for (let i = 0; i < files.length; i++) {
      const reader = new FileReader();
      reader.readAsDataURL(files[i]);
      reader.onload = () => {
        previews.push(reader.result);
        if (previews.length === files.length) {
          setImageUrl((prev) => [...prev, ...previews]);
        }
      };
    }
    try {
      if (detail) {
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
          formData.append("image[]", files[i]);
        }
        formData.append("id", params.id);

        const response = await api.post(
          "v1/dashboard/barang/gambar",
          formData,
          {
            headers: {
              "Mitra-Token": token,
            },
          }
        );
        const resData = response.data;
        if (resData.status == "success") {
          toast.success(resData.message);
          const data = await fetchData();
          setImageUrl(data.image.split(";"));
          toast.success(response.data.message);
        }
      }
      // const response = await tambahGambar();
    } catch (error) {
      toast.error(error);
    }
  };
  const labels = [
    "Aktif",
    "Tersembunyi",
    "Gadai",
    "Ditransfer",
    "Service",
    "Terjual",
  ];
  const colors = ["primary", "", "info", "error", "warning", "error"];

  return (
    <Container
      style={{
        justifyContent: "right",
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={12} md={6} lg={6} margin="0 auto">
          <div style={{ marginBottom: 10 }}>
            <Chip
              style={{ margin: "0 auto" }}
              size=""
              color={colors[status]}
              label={labels[status]}
            />
          </div>
          <Card>
            <form onSubmit={handleSubmit}>
              <CardContent>
                <input
                  type="file"
                  accept="image/*"
                  multiple
                  id="select-image"
                  style={{ display: "none" }}
                  ref={fileInputRef}
                  onChange={(e) => {
                    handleImageChange(e);
                  }}
                ></input>
                <input
                  type="file"
                  accept="image/*"
                  id="ktp-image"
                  style={{ display: "none" }}
                  onChange={(e) => {
                    handleImageKtpChange(e);
                  }}
                ></input>
                <Grid
                  container
                  elevation={3}
                  style={{ overflowX: "auto", padding: "10px 20px 10px 0" }}
                >
                  <Box display="flex" flexDirection="row">
                    {imageUrl.map((preview, index) => {
                      if (preview !== "") {
                        return (
                          <Card
                            style={{
                              flex: "0 0 auto",
                              borderRadius: "5px",
                              width: 150,
                              marginRight: 10,
                              boxShadow: "none",
                            }}
                          >
                            <CardMedia>
                              <CardMedia
                                component="img"
                                height="150"
                                image={preview}
                              />
                            </CardMedia>
                            {detail ? (
                              <center>
                                <Button
                                  variant="text"
                                  size="small"
                                  startIcon={
                                    <FontAwesomeIcon
                                      icon={faTrash}
                                    ></FontAwesomeIcon>
                                  }
                                  onClick={hapusGambar(preview)}
                                ></Button>
                              </center>
                            ) : (
                              <center>
                                <Button
                                  variant="text"
                                  size="small"
                                  startIcon={
                                    <FontAwesomeIcon
                                      icon={faTrash}
                                    ></FontAwesomeIcon>
                                  }
                                  onClick={() => {
                                    setImageUrl((prev) =>
                                      prev.filter((_, i) => i !== index)
                                    );
                                    setGambar((prev) =>
                                      prev.filter((_, i) => i !== index)
                                    );
                                  }}
                                ></Button>
                              </center>
                            )}
                          </Card>
                        );
                      }
                    })}

                    <Card
                      onClick={() => {
                        document.getElementById("select-image").click();
                      }}
                      style={{
                        flex: "0 0 auto",
                        padding: "45px 10px 30px 10px",
                        width: 150,
                        boxShadow: "none",
                        border: "dashed 1px red",
                        borderRadius: "5px",
                        marginTop: "0",
                      }}
                    >
                      <CardMedia>
                        <center>
                          <FontAwesomeIcon
                            icon={faCamera}
                            style={{
                              fontSize: "20px",
                              color: "red",
                              marginBottom: "10px",
                            }}
                          ></FontAwesomeIcon>
                        </center>
                      </CardMedia>
                      <CardContent
                        height="100"
                        style={{ padding: "5px 10px 10px 10px " }}
                      >
                        <center>
                          <Typography variant="caption" color="red" style={{}}>
                            Pilih Gambar
                          </Typography>
                        </center>
                      </CardContent>
                    </Card>
                  </Box>
                </Grid>
                <Divider variant="fullWidth" sx={{ mt: 1, mb: 2 }}></Divider>
                <TextField
                  variant="outlined"
                  size="small"
                  value={nama}
                  onChange={(event) => {
                    setNama(event.target.value);
                  }}
                  fullWidth
                  required
                  sx={{ mb: 3 }}
                  label="Nama Barang"
                ></TextField>
                <TextField
                  variant="outlined"
                  size="small"
                  value={seri}
                  onChange={(event) => {
                    setSeri(event.target.value);
                  }}
                  fullWidth
                  required
                  sx={{ mb: 3 }}
                  label="Nomor Seri"
                ></TextField>
                <TextField
                  select
                  label="Kategori"
                  defaultValue="HP"
                  fullWidth
                  size="small"
                  value={kategori}
                  onChange={(e) => {
                    setKategori(e.target.value);
                  }}
                  id="select"
                >
                  <MenuItem key="HP" value="HP">
                    HP
                  </MenuItem>
                  <MenuItem key="TV" value="TV">
                    TV
                  </MenuItem>
                  <MenuItem key="LAPTOP" value="LAPTOP">
                    LAPTOP
                  </MenuItem>
                </TextField>
                <FormControl size="small" sx={{ m: 2 }}>
                  <Typography
                    variant="body2"
                    id="demo-radio-buttons-group-label"
                    size="small"
                    color="rgba(0,0,0,0.5)"
                  >
                    Label
                  </Typography>
                  <RadioGroup
                    defaultValue="Baru"
                    name="label"
                    row
                    value={label}
                    onChange={(e) => {
                      setLabel(e.target.value);
                    }}
                  >
                    <FormControlLabel
                      value="Baru"
                      control={
                        <Radio
                          size="small"
                          sx={{
                            "&.Mui-checked": {
                              color: "red",
                            },
                          }}
                        />
                      }
                      label="Baru"
                    />
                    <FormControlLabel
                      value="Bekas"
                      control={
                        <Radio
                          size="small"
                          sx={{
                            "&.Mui-checked": {
                              color: "red",
                            },
                          }}
                        />
                      }
                      label="Bekas"
                    />
                  </RadioGroup>
                </FormControl>
                <TextField
                  variant="outlined"
                  size="small"
                  value={buy_price}
                  onChange={(event) => {
                    setBuy_price(event.target.value);
                  }}
                  fullWidth
                  required
                  sx={{ mb: 3 }}
                  label="Harga Modal"
                ></TextField>
                <TextField
                  variant="outlined"
                  size="small"
                  value={sell_price}
                  onChange={(event) => {
                    setSell_price(event.target.value);
                  }}
                  fullWidth
                  required
                  sx={{ mb: 3 }}
                  label="Harga Jual"
                ></TextField>
                <TextField
                  variant="outlined"
                  size="small"
                  value={deskripsi}
                  onChange={(event) => {
                    setDeskripsi(event.target.value);
                  }}
                  rows={4}
                  fullWidth
                  multiline
                  sx={{ mb: 3 }}
                  label="Keterangan Tambahan"
                ></TextField>
                <Accordion disableGutters elevation={0} square>
                  <AccordionSummary
                    expandIcon={<FontAwesomeIcon icon={faPlus} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant="body2" sx={{ fontSize: "12px" }}>
                      Subsidi
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TextField
                      select
                      label="Seeker"
                      sx={{ mb: 3 }}
                      fullWidth
                      size="small"
                      defaultValue="tidak"
                      value={seeker}
                      onChange={(e) => {
                        setSeeker(e.target.value);
                      }}
                      id="select"
                    >
                      <MenuItem key="tidak" value={null}>
                        Tidak Ada
                      </MenuItem>
                      {data_account.map((option) => (
                        <MenuItem key={option.name} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      select
                      label="Kurir Pembelian"
                      defaultValue="tidak"
                      fullWidth
                      sx={{ mb: 3 }}
                      size="small"
                      value={kurir}
                      onChange={(e) => {
                        setKurir(e.target.value);
                      }}
                      id="select"
                    >
                      <MenuItem key="tidak" value={null}>
                        Tidak Ada
                      </MenuItem>
                      {data_account.map((option) => (
                        <MenuItem key={option.name} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      select
                      label="Kurir Penjualan"
                      defaultValue="tidak"
                      fullWidth
                      size="small"
                      value={kurirPenjualan}
                      onChange={(e) => {
                        setKurirPenjualan(e.target.value);
                      }}
                      id="select"
                    >
                      <MenuItem key="tidak" value={null}>
                        Tidak Ada
                      </MenuItem>
                      {data_account.map((option) => (
                        <MenuItem key={option.name} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </AccordionDetails>
                </Accordion>
                {status === 2 ? (
                  ""
                ) : (
                  <FormControlLabel
                    sx={{ fontSize: "1px", mb: 1 }}
                    control={
                      <Checkbox
                        onChange={(e) => {
                          setGadai(e.target.value);
                        }}
                        size="small"
                      />
                    }
                    label={<Typography variant="body2">Gadai</Typography>}
                  />
                )}

                <Divider variant="fullWidth" sx={{ mb: 2 }}></Divider>

                <Button
                  onClick={handleSubmit}
                  size="small"
                  variant="contained"
                  fullWidth
                >
                  Simpan
                </Button>
              </CardContent>
            </form>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default BarangDetail;
