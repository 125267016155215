import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import api from "../ApiDashboard";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Paper from "@mui/material/Paper";
import {
  Container,
  Box,
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  Divider,
  IconButton,
  Stack,
} from "@mui/material";
import Slider from "react-slick";
import TableComponent from "../../components/table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa";
import { MutatingDots } from "react-loader-spinner";
import { Pagination, Table, Spin } from "antd";
import { faAdd, faTimes } from "@fortawesome/free-solid-svg-icons";

const BarangKeluar = () => {
  const token = localStorage.getItem("Mitra-Token");
  const navigate = useNavigate();

  const fetchData = async (page, rowsPerPage) => {
    try {
      const response = await api.get(
        `v1/dashboard/barang/keluar?page=${page}&per_page=${rowsPerPage}`,
        {
          headers: { "Mitra-Token": token },
        }
      );
      return response.data.data;
    } catch (error) {
      throw error;
    }
  };
  const goTo = (path) => () => {
    navigate(path);
  };
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(0);
  const [pageSize, setPageSize] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchDataAndStopLoading = async () => {
      try {
        const responseData = await fetchData(page, rowsPerPage);
        setData(responseData.data);
        setRowsPerPage(responseData.last_page);
        setTotal(responseData.total);
        setPageSize(responseData.per_page);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };

    fetchDataAndStopLoading();
  }, [page, rowsPerPage]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const columns = [
    {
      title: "ID",
      fixed: "left",
      dataIndex: "id",
      key: "id",
      width: "5%",
    },
    { title: "ID Barang", dataIndex: "id_barang", key: "id_barang" },
    { title: "Nama Barang", dataIndex: "name", key: "nama" },
    { title: "No Seri", dataIndex: "no_seri", key: "id_barang" },
    { title: "Seller", dataIndex: "seller", key: "seller" },
    { title: "Kurir", dataIndex: "courier", key: "courier" },
    { title: "Bukti Kurir", dataIndex: "courier_image", key: "courier_image" },
    { title: "Tanggal Keluar", dataIndex: "created_date", key: "created_date" },
  ];
  // const tokenString = localStorage.getItem("Mitra-Token");
  return (
    <Container
      style={{
        justifyContent: "right",
      }}
    >
      {" "}
      <Grid container spacing={1}>
        <Grid item xs={8} md={8} lg={8}>
          <Typography gutterBottom variant="h6" component="div">
            Barang Keluar
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12} lg={12}>
          <Card>
            <CardContent>
              <Typography gutterBottom variant="body" component="div">
                Jumlah {total}
              </Typography>

              <Table
                scroll={{
                  x: 1000,
                  y: 300,
                }}
                columns={columns}
                dataSource={data}
                pagination={false}
                loading={isLoading}
              />
              <Pagination
                style={{ marginTop: "10px" }}
                current={page}
                pageSize={pageSize}
                total={total}
                onChange={handlePageChange}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default BarangKeluar;
