import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashCan,
  faTruckArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { Grid, Modal, TextField } from "@mui/material";

function ModalTransfer({ isOpen, onClose, onChange }) {
  if (!isOpen) return null;
  const [itemId, setItemId] = useState("");
  const [deskripsi, setDeskripsi] = useState("");

  const handleTransfer = () => {
    onChange(itemId, deskripsi);
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 300,
          p: 2,
          textAlign: "center",
        }}
      >
        <Typography id="modal-modal-title" variant="h6" component="h3">
          KONFIRMASI
        </Typography>
        <FontAwesomeIcon
          icon={faTruckArrowRight}
          style={{ fontSize: "50px", marginTop: "20px" }}
        />
        <Typography id="modal-modal-description" sx={{ mt: 4 }}>
          Transfer ke:
        </Typography>

        <Grid container spacing={1} sx={{ mt: 1 }}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={itemId}
              onChange={(e) => {
                setItemId(e.target.value);
              }}
              sx={{ mb: 2 }}
              label="Mitra ID"
            ></TextField>
            <TextField
              variant="outlined"
              size="small"
              value={deskripsi}
              onChange={(event) => {
                setDeskripsi(event.target.value);
              }}
              rows={4}
              fullWidth
              multiline
              label="Keterangan"
            ></TextField>
          </Grid>
          <Grid item xs={6}>
            <Button variant="outlined" onClick={onClose} fullWidth>
              BATAL
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" onClick={handleTransfer} fullWidth>
              TRANSFER
            </Button>
          </Grid>
        </Grid>
      </Card>
    </Modal>
  );
}

export default ModalTransfer;
