import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDollarSign,
  faPlus,
  faTrashCan,
  faTruckArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  MenuItem,
  Modal,
  TextField,
} from "@mui/material";
import api from "../../pages/ApiDashboard";
import { toast } from "react-toastify";

function ModalTerjual({ isOpen, onClose, onChange, price }) {
  if (!isOpen) return null;

  const token = localStorage.getItem("Mitra-Token");
  const [data_account, setData] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [final_price, setFinalPrice] = useState(price);
  const [cour, setCour] = useState("");
  const [other, setOther] = useState("");
  useEffect(() => {
    const staff_account = api
      .get("v1/dashboard/staff_account", {
        headers: {
          "Mitra-Token": token,
        },
      })
      .then((response) => {
        setDataLoaded(true);
        const { status, message, data } = response.data;
        setData(data);
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else if (error.message) {
          toast.error(error.message);
        } else {
          toast.error("An error occurred. Please try again later.");
        }
      });
  }, []);

  const handleTransfer = () => {
    onChange(final_price, other, cour);
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 300,
          p: 2,
          textAlign: "center",
        }}
      >
        <Typography id="modal-modal-title" variant="h6" component="h3">
          KONFIRMASI
        </Typography>
        <FontAwesomeIcon
          icon={faDollarSign}
          style={{ fontSize: "50px", marginTop: "20px" }}
        />
        <Typography sx={{ mt: 4 }}>Terjual</Typography>

        <Grid container spacing={1} sx={{ mt: 1 }}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={final_price}
              onChange={(e) => {
                setFinalPrice(e.target.value);
              }}
              sx={{ mb: 2 }}
              label="Harga Akhir"
            ></TextField>
            <Accordion disableGutters elevation={0} square>
              <AccordionSummary
                expandIcon={<FontAwesomeIcon icon={faPlus} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="body2" sx={{ fontSize: "12px" }}>
                  Subsidi
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TextField
                  select
                  label="Seeker"
                  sx={{ mb: 3 }}
                  fullWidth
                  size="small"
                  defaultValue="tidak"
                  value={other}
                  onChange={(e) => {
                    setOther(e.target.value);
                  }}
                  id="select"
                >
                  <MenuItem key="tidak" value={null}>
                    Tidak Ada
                  </MenuItem>
                  {data_account.map((option) => (
                    <MenuItem key={option.name} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  select
                  label="Kurir"
                  defaultValue="tidak"
                  fullWidth
                  size="small"
                  value={cour}
                  onChange={(e) => {
                    setCour(e.target.value);
                  }}
                  id="select"
                >
                  <MenuItem key="tidak" value={null}>
                    Tidak Ada
                  </MenuItem>
                  {data_account.map((option) => (
                    <MenuItem key={option.name} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={6}>
            <Button variant="outlined" onClick={onClose} fullWidth>
              BATAL
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" onClick={handleTransfer} fullWidth>
              JUAL
            </Button>
          </Grid>
        </Grid>
      </Card>
    </Modal>
  );
}

export default ModalTerjual;
